<template>
  <fki-sub-nav
    is-active
    :color="page.color"
    :items="items"
  />
  <fki-container
    fixed
    top="196px"
    bottom="60px"
    left="0"
    width="100%"
    column
  >
    <fki-container-header
      :color="page.color"
      min-height="100px"
      size="30px"
      :title="page.body"
    />
    <div class="fki-terms">
      <div
        v-if="currentSection === 'general'"
        class="general"
      >
        <h2>TERMS AND CONDITIONS</h2>

        <h3>1. DEFINITIONS: In this document, the following definitions apply.</h3>
        <p>
          <strong>1.1. "Agreement"</strong> means all bids, purchase orders, and all other documents, between Company and Customer relating to services or materials contracted or provided and includes these Terms and Conditions..
          <br>
          <strong>1.2. "Company"</strong> means Foto-Kem Industries, Inc., a California corporation, dba FotoKem Film and Video, Keep Me Posted, L.A. Studios, Margarita Mix Hollywood and Margarita Mix Santa Monica.
          <br>
          <strong>1.3. "Company Liability"</strong> means any and all liabilities, losses, damages including consequential and incidental damages, demands, claims, causes of action, judgments, and all related legal fees, costs and expenses.
          <br>
          <strong>1.4. "Customer"</strong> means the person or entity applying for an account or requesting services or materials from Company, and any employees, representatives, or agents of person or entity contracting services.
          <br>
          <strong>1.5. "Elements"</strong> means all physical film and/or video media containing intellectual property and associated rights as provided by Customer or created by Company on Customer's request.  Elements shall also mean any file-based data recordings containing intellectual property and associated rights as provided by Customer or created by Company on Customer's request, and all physical storage devices used to record, transport and archive file-based data content.
        </p>

        <h3>2. OWNERSHIP OF ELEMENTS:</h3>
        <p>
          <strong>2.1.</strong>
          <span class="underline">Warranty:</span> Customer represents and warrants that Customer is the sole owner or the duly authorized representative of all owners of the Elements delivered to Company by Customer (or for Customer's account) and all intellectual property contained in or represented by the Elements.
          <br>
          <strong>2.2.</strong>
          <span class="underline">Authority:</span> Customer represents and warrants that Customer has the legal right and authority to enter into the Agreement and to engage Company to perform all work under the Agreement, and that all facts stated in any document comprising the Agreement are true and complete.
          <br>
          <strong>2.3.</strong>
          <span class="underline">Transfer:</span> As long as Customer is indebted to Company, Customer shall not sell, assign, pledge, hypothecate, loan or otherwise encumber or purport to transfer all or any interest in any of the Elements or any right therein without prior written notice to Company.
          <br>
          <strong>2.4.</strong>
          <span class="underline">Indemnity:</span> Customer shall indemnify, defend and hold Company harmless from all actual or potential Company Liability, arising out of or in connection with the breach of any Customer warranty or representation or the publication, processing, use, distribution, contents or exhibition of Elements including, without limitation, any claimed libel, slander, defamation, invasion of privacy, misappropriation of personality or infringement of patent, copyright, trademark, trade name, or other proprietary right.
          <br>
          <strong>2.5.</strong>
          <span class="underline">Claim:</span> Company shall have no obligation to investigate the validity of any claim adverse to Customer or Company by any third party with regard to ownership, right to possession or control of the contents of the Elements or any other matter.  Customer shall indemnify, defend and hold Company harmless from any and all actual or potential Company Liability that may result from action taken by Company as a result of any such claim.
          <br>
          <strong>2.6.</strong>
          <span class="underline">Response:</span> Within forty-eight (48) hours after Company provides notice of a claim to Customer, Customer shall notify Company in writing of the response Customer proposes.  Thereafter, Company, at its sole discretion, shall have the option to take any reasonable action Company deems appropriate to protect its interests including, but not limited to, releasing or otherwise dealing with any or all of the Elements as requested in the claim, rejecting the claim in whole or in part, restricting access to the Elements or interpleading the Elements.  Customer shall indemnify, defend and hold Company harmless from all actual or potential liability arising out of or in connection with any such action.
        </p>
        <h3>3. PAYMENT:</h3>
        <p>
          3.1.
          <span class="underline">Prices, Sales and other Taxes:</span> All prices are F.O.B. Company's facility, and do not include sales, use, manufacturing, excise, processing or other taxes, which may be imposed by any government authority.  Customer shall indemnify Company for all taxes which Company may be required to pay or collect under any existing or future law in connection with the services or goods provided by Company.
          <br>
          <strong>3.2.</strong>
          <span class="underline">Terms and Adjustments:</span> Each invoice from Company shall be due and payable on presentation, or if Company has granted Customer an open account, within thirty (30) days of the invoice date.  Customer shall comply with credit terms extended by Company.  Any invoice not paid when due shall bear interest at the rate of 1.5% per month (18% per year).  Customer shall present any claim for adjustment of an invoice in writing within thirty (30) days from the date of the invoice.  Customer waives any claim for adjustment which is not presented within this time.
          <br>
          <strong>3.3.</strong>
          <span class="underline">Third Party Payment:</span> Company shall have no obligation to accept work submitted by Customer to be billed to a third party.  If Company accepts such work, Customer and the third party are jointly and severally liable for payment to Company.
          <br>
          <strong>3.4.</strong>
          <span class="underline">Shipping:</span> After receipt of written instructions from Customer, Company shall use reasonable efforts seeking to cause Elements to be transported to the destination requested, all at Customer's expense and risk.  In the absence of written instructions identifying the carrier or means of shipment, Company shall have the right to make all shipments of Elements via carrier or means Company selects.  Unless requested by Customer in writing, Company will not insure shipments through the carrier selected.
          <br>
          <strong>3.5.</strong>
          <span class="underline">Overtime:</span> Customer acknowledges that rates in Company's price list are based on use and operation of Company's facilities and employment of personnel and equipment during normal working hours, and are subject to increase, on notice to Customer, for work performed on Saturdays, Sundays, holidays or otherwise outside normal working hours.
          <br>
          <strong>3.6.</strong>
          <span class="underline">Price Changes:</span> Company may from time to time increase charges shown on the price list based on changes in prices of film, chemicals, other materials used by Company, manufacturing or labor (which shall also include fringe benefits) or other costs or market conditions.  Company shall endeavor to give notice to Customer of any such price change.
          <br>
          <strong>3.7.</strong>
          <span class="underline">Cancellation Fees:</span> In the event of untimely cancellation of work ordered or scheduled, Customer shall pay Company cancellation fees according to the schedule on Company's price list, or as provided below, whichever is less.
          <br>
          <strong>3.7.1.</strong>
          <span class="underline">All Film Products, Video and Data Duplication, Transcoding and Archiving, or Other Non-Hourly Service:</span> Cancellation
          fees will be determined by Company relative to the amount of work completed.
          <br>
          <strong>3.7.2.</strong>
          <span class="underline">Editing, Mastering, Telecine, Graphics, VFX, Audio Recording, or any other Hourly Services:</span> Cancellation made twenty-four (24) hours or more (excluding weekends) before the time work is scheduled by Company to begin will incur no cancellation fee.  Cancellation made less than twenty-four (24) hours prior to the time work is scheduled by Company to begin will incur a cancellation fee of 100% of the estimated costs of the project.
          <br>
          <strong>3.8.</strong>
          <span class="underline">Attorneys' Fees:</span> Customer shall reimburse all attorneys' fees incurred by Company in or in connection with (a) any litigated or non-litigated dispute between Customer and any other person or entity which involves or results in a demand or conflicting demands on Company; (b) any interpleader action involving Company; (c) any litigation or non-litigation action taken by Company to demand payment of, or for the collection of, amounts due or overdue to Company; (d) the interpretation or enforcement of this Agreement; or (e) any other dispute between or involving Customer and Company.
        </p>
        <h3>4. RIGHT TO REFUSE PERFORMANCE:</h3>
        <p>
          <strong>4.1.</strong>
          <span class="underline">Generally:</span> In addition to Company's rights above, Company may, without liability to Customer, refuse to perform any service or provide any goods which Company, in its sole discretion, believes could potentially expose Company to a claim or be found to be in any way unlawful, pornographic, defamatory or degrading, or which Company deems as potentially tending to incite prejudice or passion.  If Company determines that any such risk exists, Company shall have the right to stop further work, entirely or in part, and retain possession of all Elements until Customer has paid in full for work performed.  Other than the obligation to return the Elements on receipt of payment in full from Customer, Company shall incur no further obligation of any kind to Customer.
          <br>
          <strong>4.2.</strong>
          <span class="underline">Imperfect or Old Elements:</span> Company shall have sole discretion to refuse to accept for processing Elements in old, shrunken, or other imperfect or unusual photographic or physical condition, or any nitrate material.  If any such material is accepted for processing, Company shall not be responsible for resulting defects.  Company shall have the right, on notice to Customer, to make additional charges for time and materials used, whether or not a satisfactory Element is produced by Company.
        </p>
        <h3>
          5. LIMITATIONS OF WARRANTY: EXCEPT AS EXPRESSLY STATED IN THESE TERMS AND CONDITIONS, COMPANY MAKES NO WARRANTY,
          EITHER EXPRESS OR IMPLIED, AS TO MERCHANTABILITY, FITNESS FOR A PARTICULAR USE OR PURPOSE, DESCRIPTION, QUALITY,
          CHARACTER, OR OTHERWISE WITH RESPECT TO THE SERVICES OR GOODS PROVIDED BY COMPANY.
        </h3>
        <p />
        <h3>6. STORAGE AND REMOVAL:</h3>
        <p>
          <strong>6.1</strong>
          <span class="underline">Storage:</span> At Customer's written request, and sole risk, Company will store Elements during the time of production, release printing or dubbing and for a reasonable period afterward, up to six (6) months.  Thereafter, Customer shall pay Company a storage charge of $2.00 per month per unit of film, tape, disk or drive, or $.50 per gigabyte per month for data storage on Company servers or such other rate as Company deems appropriate for storage charge.  Company may store such Elements at any place or places Company deems appropriate, still at Customer's risk.  At any time, Company may require Customer to retake possession of any or all of Customer's Elements (vaulted or otherwise).
          <br>
          <strong>6.2</strong>
          <span class="underline">Request to Store in Vault:</span> Storage of physical Elements in a Company vault may, but does not necessarily, provide security or safety additional to Company's other storage methods.  Company shall have no obligation to store Customer's physical Elements in a vault, unless Company agrees to Customer's written request to do so.  Company shall use reasonable efforts seeking to implement Customer's reasonable instructions.  Company shall not be liable for inaccurate instructions or failure to comply with instructions.
          <br>
          <strong>6.3</strong>
          <span class="underline">Name.</span> Customer shall have sole responsibility to ensure that the physical Elements and each of their components are vaulted under the proper Customer name.  If any physical Elements are vaulted in the name of a third party, Company may release the physical Elements to the third party or a representative thereof without notice to Customer or liability.  Company reserves the right to request a written request authorizing the release of each vaulted or stored Element, signed by an individual specifically authorized as set forth on the Customer Profile or written modification thereof.  Company shall have no obligation to provide Elements stored in Company's vault to any individual other than indicated on the Customer Profile.  Customer shall notify Company in writing of any change of individuals authorized to receive vaulted or stored Elements.
          <br>
          <strong>6.4</strong>
          <span class="underline">Elements Outside the Vault.</span> Company shall have the right to make physical Elements not stored in the vault available for pick-up on a will-call basis, and to release such Elements at the will-call window to individuals who purport to have authority to receive them.  Customer shall notify Company in advance in writing of any requested limitation or restriction of the release of physical Elements at Company's will-call window.  Company will not be responsible nor incur liability for release of any Elements at the will-call window.  Company reserves the right to vault or otherwise store any physical Elements that remain unclaimed in Will-Call after fourteen (14) days.  Storage charges on these Elements will apply immediately.
          <br>
          <strong>6.5</strong>
          <span class="underline">Removal.</span> Within thirty (30) days after written notice to Customer at Customer's last known address as it appears in Company's records, Customer shall, at Customer's sole expense, remove all Elements in storage, whether in the vault, server, or otherwise.  If Customer fails to do so, at Company's sole option.  Company shall have the right, without liability to Customer or any other person, to either (1) ship or deliver physical Elements at Customer expense to Customer at Customer's last known address as it appears in Company's records; (2) send Customer's physical Elements to a public warehouse or film storage facility to be held in the name of, for the account of, and at the sole risk of Customer, at a rate obtained in good faith by Company; or (3) destroy or otherwise dispose of the physical Elements.  Within thirty (30) days after written notice to Customer, as described above, Company shall have the right to purge or erase Customer's data Elements from Company servers, should Customer fail to respond or act on Company's written notice.
          <strong>THE LIMITATION OF LIABILITY PROVISIONS OF SECTION 9 SHALL APPLY TO THE DISPOSITION OR DESTRUCTION OF CUSTOMER'S ELEMENTS IN ACCORDANCE WITH THIS SECTION 6.5.</strong>
        </p>
        <h3>7. STANDARD FILM SYNCHRONIZATION LEADERS:</h3>
        <p>
          Customer shall supply film negatives to Company with standard leaders bearing clean and proper synchronization marks.  Any errors in synchronization shall be the sole responsibility of the Customer, and Company shall bear no liability related thereto.  Customer shall indemnify, defend and hold Company harmless from any Company liability arising out of, or connected with, Element synchronization.
        </p>
        <h3>8. MISCELLANEOUS:</h3>
        <p>
          <strong>8.1.</strong>
          <span class="underline">All Work:</span> This Agreement applies to all Company work performed, services rendered, and materials furnished for the account of Customer until rescinded, terminated or modified by a subsequent written agreement.
          <br>
          <strong>8.2.</strong>
          <span class="underline">Entire Agreement:</span> The entire Agreement includes all documents as described in Definitions 1.1 and includes, without limitation, all representations and warranties, agreed by the parties, and replaces and supersedes any provisions on the face or reverse side of any purchase order submitted by Customer other than Company's form or any attachment thereto or any prior general agreement inconsistent with the terms hereof.  There are no terms and conditions, either oral or written, between the parties, except as stated in the Agreement herein.  No modification or waiver or unenforceability of any one or more provisions of this Agreement shall affect the validity or enforceability of the remaining provisions.
          <br>
          <strong>8.3.</strong>
          <span class="underline">Remedies Are Cumulative:</span> The failure of Company in any one or more instances to insist on performance of any provision of this Agreement or to exercise any right or privilege in this Agreement shall not be construed as a waiver of any breach or of any other right or privilege.
          <br>
          <strong>8.4.</strong>
          <span class="underline">Interpretation:</span> Section headings are for convenience only and shall have no effect on the interpretation of the Agreement.  The terms of Agreement shall be interpreted and construed according to their fair meanings and not strictly for or against Customer or Company.
          <br>
          <strong>8.5.</strong>
          <span class="underline">Governing Law:</span> Any claim of breach, or dispute as to validity, construction or performance of this Agreement shall be governed by and construed and enforced according to laws of the State of California.  Jurisdiction and venue for any suit, action or proceeding arising out of or relating to the Agreement, shall be proper in any State or Federal court in the County of Los Angeles, State of California.
          <br>
          <strong>8.6.</strong>
          <span class="underline">Notices and Communications:</span> All notices and communications required or permitted hereunder to Company shall be sent to: Foto-Kem Industries, Inc., P.O. Box 7755, Burbank, California 91510, unless Company notifies Customer otherwise in writing.  All notices and communication to Customer shall be sent to Customer at any mailing address and/or email address shown as an address of Customer in Company's records.  Notices and communications sent by mail shall be deemed to be delivered at the earlier of actual receipt or three (3) days after dispatch.  Notice sent by email shall be conspicuously labeled to call special attention to it and deemed to be delivered the business day after successful transmission.
          <br>
          <strong>8.7.</strong>
          <span class="underline">Credit Application/References:</span> If Customer applies for credit with Company, Customer authorizes Company to verify the information stated by Customer on the credit application and to obtain any other information Company may request.  References are hereby authorized and directed to release such information to Company.
          <br>
          <strong>8.8.</strong>
          <span class="underline">Modification:</span> The terms of this Agreement shall be modified and/or superseded by the terms of any future forms of these terms and conditions appearing on any invoice or other standard form provided by Company to Customer or at www.fotokem.com/terms.  These Terms and Conditions as revised 12-19 are retroactively effective to Customers first work performed by Company and to the date Customer's Elements were first stored by Company.  These terms may be amended, waived or modified only by a written agreement signed by Customer and by an authorized officer of Company.  Sales personnel of Company are not authorized officers of Company for this purpose.
        </p><p />
        <h3>9. LIABILITY LIMITATIONS AND INSURANCE:</h3>
        <p>
          <strong>9.1.</strong>
          <span class="underline">Acknowledgments:</span> Customer acknowledges that Company's prices are based on Company's assessment of the value of its good and services, and are not based on value of Elements.  Customer acknowledges and agrees that Elements are received, processed, and stored at Customer's risk.  Additionally, Company reserves the right to provide Elements to any person to whom Company assigns or subcontracts all or any of the work.  Customer acknowledges being informed that
          <strong>COMPANY IS NOT OBLIGATED TO PURCHASE INSURANCE FOR THE ELEMENTS AND THAT CUSTOMER SHALL BE SOLELY RESPONSIBLE
            TO INSURE THE ELEMENTS.</strong>
          <br>
          <strong>9.2.
            <span class="underline capitalize">Liability Limits for Damage to or Loss of Elements:</span></strong>
          <strong class="capitalize">Company shall not be liable for lost profits, incidental, consequential, special, or other damages caused by (a) loss, damage or destruction of any Elements, or other property; (b) delay in providing or failing to provide goods or services; (c) defective products or serviceS provided by Company; or (d) errors by Company in labeling or shipping or delivery, even if any of the foregoing, (a) through (d), are caused by negligence of Company or Company's personnel.  Company's liability shall be limited to replacement of a similar quantity of unexposed raw stock or new unrecorded tapes/DISKS for the quantity of the Elements which are lost, damaged or destroyed, return of Elements when goods or services have been delayed or not provided, replacement or repair of defective goods or services provided by Company, correction of labeling or shipping or delivery error.  Company shall have no further liability regarding such Elements.  Company's total liability to Customer or anyone else for any reason whatsoever shall not exceed the portion of the price actually paid by Customer to Company for that portion of the goods furnished and/or services rendered by Company under this Agreement.</strong>
          <br>
          <strong>9.3.</strong>
          <span class="underline">Prompt Notice and Time Bar:</span> Customer shall return any defective Element created by Company and provide Company written notice of any imperfection or error or claim in the time stated in Section 9.5.
          <br>
          <strong>9.4.
            <span class="underline capitalize">Liability Limitations for Delay and Delivery:</span> </strong>
          <strong class="capitalize">SHIPPING AND DELIVERY DATES ARE ONLY APPROXIMATIONS.  COMPANY SHALL NOT BE LIABLE TO CUSTOMER OR ANY OTHER PERSON FOR ANY LOSS, DAMAGE OR LIABILITY CAUSED BY DELAY IN DELIVERY OR SHIPPING, OR FAILURE TO PROVIDE NOTICE OF ANY DELAY.  Additionally, Company shall not be liable for delay caused by any reason including, but not limited to, weather, act of God, act of Customer, act of civil or military authority, fire, flood, earthquake, epidemic, quarantine, war, ACT OF TERROR, riot, strike, labor difficulties, equipment failure, delay in transportation or shortage in material.  In the event of delay, the approximate delivery or shipping date shall be deemed extended to a period no less than the length of the delay.</strong>
          <br>
          <strong>9.5.</strong>
          <span class="underline">Claims and Notice:</span> Customer shall notify Company in writing of any claim or potential claim related to or arising out of the services provided under this Agreement within ninety (90) days after the earliest of shipment or the date Customer knew or the date the Customer should have known of such claim.  Any claim that Customer fails to provide written notice of within this time shall be deemed to be waived, released and permanently barred.
          <br>
          <strong>9.6.</strong>
          <span class="underline">Insurance:</span> Company shall have no obligation to insure any of Customer's Elements while in Company's possession, in transit or otherwise.  Customer acknowledges Company's recommendation that Customer order a protection film Element of each cut negative and fully insure physical Elements against all insurable risks including loss, destruction or damage while in Company's possession, in transit or otherwise.  Customer shall be solely responsible to fully insure all Elements.  Customer waives all rights of subrogation.  Without limiting other provisions within Section 9 pertaining to limitation of damages, in no event shall Company be liable for any loss of or damage to the Elements.
          <br>
          <strong>9.7.
            <span class="underline">NO CONSEQUENTIAL DAMAGES:</span> IN NO EVENT SHALL COMPANY, ITS EMPLOYEES, AGENTS OR SUBCONTRACTORS, BE LIABLE FOR ANY LOST PROFITS OR SPECIAL, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES WHATSOEVER INCLUDING, BUT NOT LIMITED, TO THOSE RESULTING FROM ANY DEFECTIVE ELEMENTS, PRODUCTS OR EQUIPMENT DELIVERED, PRODUCED OR PROVIDED BY THE COMPANY; RESULTING FROM LOSS OF, DAMAGE TO, OR DESTRUCTION OF THE ELEMENTS FROM IMPROPER STORAGE, PROCESSING, PACKING, RELEASE, DELAY IN DELIVERY OR SHIPMENT, OR ERRORS IN SHIPMENT OR LABELING BY COMPANY.</strong>
        </p>
        <h3>10. COMPANY LIENS:</h3>
        <p>
          <strong>10.1.</strong>
          <span class="underline">Security Interest:</span> In addition to any other liens, rights or remedies of Company under this Agreement or applicable law, Customer grants, assigns and transfers to Company a security interest in and lien on all Customer's rights, title and interest in the Elements of Customer in the possession of Company, as security for payment for any and all services performed and goods furnished to Customer by Company.  If the Elements are voluntarily delivered to Customer or authorized third person prior to payment to Company for such materials and services, the liens and security interest shall not be extinguished but shall survive until payment in full has been received.  Customer grants Company its power of attorney to execute and file such documents including any UCC financing statements as may be required or become necessary or appropriate to perfect and give public notice of such security interest.
          <br>
          <strong>10.2.
            <span class="underline capitalize">Cumulative Rights:</span></strong>
          <strong>ALL LIENS, RIGHTS AND REMEDIES OF COMPANY SHALL BE DEEMED CUMULATIVE AND NOT EXCLUSIVE OF ONE ANOTHER.  THE EXERCISE BY COMPANY OF ANY RIGHT OR REMEDY SHALL NOT PRECLUDE ITS RIGHT TO ENFORCE ANY ONE OR MORE OTHER RIGHTS AND REMEDIES.  IF COMPANY EXERCISES ITS RIGHT HEREUNDER BY SELLING THE ELEMENTS AT PUBLIC OR PRIVATE SALE, THEN CUSTOMER AGREES TO PAY TO COMPANY ON WRITTEN DEMAND WHATEVER DEFICIENCY MAY BE DUE AFTER THE PROCEEDS ARE APPLIED TO PAYMENT OF THE INDEBTEDNESS INCLUDING, WITHOUT LIMITATION, ALL LEGAL AND OTHER COSTS, EXPENSES AND CHARGES INCURRED IN THE COLLECTION, SALE, DELIVERY OR PRESERVATION OF THE ELEMENTS.</strong>

          <br>
          <strong>10.3.</strong>
          <span class="underline">Title to Buyer:</span> If Company shall enforce any rights under this Agreement or applicable law, Company or any other party acquiring any right, title or interest in or to any Elements at public or private sale is hereby granted all rights, title and interest of Customer in such Elements including, without limitation, the unrestricted right and license to distribute, exhibit and otherwise exploit such Elements by all media for its own account.
        </p>
        <h3>11. ATTESTATION:</h3>
        <p>
          Customer represents and warrants that the representations made by Customer in this Agreement are true and correct; that Customer has read the foregoing terms and conditions; and agrees that all Elements submitted to Company, and work performed and services rendered for the account of Customer, shall be governed by this Agreement.
        </p>
      </div>

      <div
        v-if="currentSection === 'rental'"
        class="rental"
      >
        <h2>
          EQUIPMENT RENTAL and SERVICE AGREEMENT
          <br> Terms &amp; Conditions
        </h2>
        <p>
          This Equipment Rental and Service Agreement ("Agreement") and all other associated documents related to FotoKem services
          and/or the lease or rental of FotoKem equipment are the "Transaction Documents" and collectively are the "Contract"
          between the parties. FotoKem Equipment include, but are not limited to, nextLAB&reg; on-location and/or near-location
          systems, non-linear editing equipment, videotape recording and playback equipment, removable storage media, portable
          data storage drives, cameras or network equipment (the "Equipment"). Post production and/or other services (the
          "Services") described in the Transaction Documents, may also include any Data Hosting and Data Transmission Services,
          as defined below.
        </p>
        <h3>DEFINITIONS.</h3>
        <p>
          <strong>a) "FotoKem"</strong> shall mean and refer to Foto-Kem Industries, Inc., a California corporation, owner of the
          Equipment and/or provider of any rental or leasing services specified in the Contract.
          <br>
          <strong>b) "Customer"</strong> shall mean and refer to the other party whose name appears in the Equipment Rental and Services
          Agreement Contract.
          <br>
          <strong>c) "Contract"</strong> means all Bids, orders, or other Transaction Documents pertaining to rental or lease of
          Equipment, which includes these Terms and Conditions, and may also include (but not be limited to) post production,
          Data Hosting, Data Transmission, and other FotoKem Services.
          <br>
          <strong>d) "Rental Lease Period"</strong> means the Contract term of the Equipment rental or lease, and/or the actual period
          of time from when the Equipment leaves FotoKem to the time it is returned to FotoKem.
          <br>
          <strong>e) "Service Period"</strong> means the contracted term of Equipment rental, or actual duration of time required
          to complete Contracted Services.
          <br>
          <strong>f) "Customer Data"</strong> shall refer to digital moving or still images, digital sound, and other data or metadata
          provided by Customer to FotoKem or created by Customer and recorded or stored in the Equipment.
          <br>
          <strong>g) "Archival Data"</strong> means high-value content data files, stored on server, data tape, or other archival
          media.
          <br>
          <strong>h) "System"</strong> means FotoKem's networks, mobile or fixed servers, the Equipment, and related hardware and
          software components that permit users to store, edit, copy, manipulate, and access digital data.
          <br>
          <strong>i) "Data Hosting Services"</strong> refers to Services provided by FotoKem and its System that facilitate the recordation,
          storage, upload, download and manipulation of Customer Data.
          <br>
          <strong>j) "Data Storage Services"</strong> means the short and/or long term storage or archive of Customer Data.
          <br>
          <strong>k) "Data Transmission Services"</strong> refers to Services provided by FotoKem and its System that facilitates
          the transmission of Customer Data via Internet, satellite, or other data transmission means.
          <br>
          <strong>l) "Bid"</strong> means the pricing proposal presented to Customer by FotoKem.
        </p>
        <h3>1. Rental/Lease Period</h3>
        <p>
          Customer agrees to rent/lease the Equipment from FotoKem for the contracted price and Rental/Lease Period and pursuant
          to the other terms and conditions stated in the rental/lease Contract. Daily, weekly, or monthly rent/lease rates
          specified in the rental/lease Contract shall accrue at all times during the Rental/Lease Period. The Rental/Lease
          Period shall start on the date and time when the Equipment leaves FotoKem premises, or on delivery of Equipment
          to Customer, as stated in other Transaction Documents, and shall continue with respect to each item of Equipment
          until the item is returned to FotoKem premises in proper working order as stated in Section 14 below. No credit
          against the amount of rent/lease rates that accrue shall be allowed on the basis of non use of Equipment by Customer
          during the Rental/Lease Period. Acceptance of the Equipment by FotoKem on its return by Customer shall not waive
          or prejudice any claim that FotoKem has or may have against Customer. All accumulated rent/lease payments must
          be paid by Customer, as stated in other Transaction Documents. No amount paid by Customer as a rent, lease payment
          shall be applied toward any purchase of the Equipment by Customer.
        </p>

        <h3>2. Services and Service Period</h3>
        <p>
          FotoKem shall provide Services at Contracted or otherwise applicable service rates during the Service Period and pursuant
          to any other terms and conditions stated in the Contract including but not limited to any Terms that may be stated
          in attached Exhibits. Service fees as described in the Transaction Documents shall accrue at all times during the
          Service Period. The Service Period shall start on the date the Services are initially made available to Customer,
          and shall include the period of use specified in the other Transaction Documents, and shall continue with respect
          to each Service until that Service is terminated in accordance with the Transaction Documents. Any FotoKem personnel
          providing Equipment Services at Customer determined locations shall be deemed to be a representative of Customer
          and under Customer's direction and control while providing Services. FOTOKEM SHALL NOT HAVE LIABILITY OR RESPONSIBILITY
          TO CUSTOMER FOR THE ACTS OR OMISSIONS OF FOTOKEM PERSONNEL ENGAGED IN PROVIDING LOCATION SERVICES, INCLUDING WITHOUT
          LIMITATION ANY RESULTING PERSONAL INJURY, PROPERTY DAMAGE OR LOSS, CORRUPTION, DESTRUCTION OR PUBLICATION OF CUSTOMER
          DATA, WHETHER OR NOT COVERED BY CUSTOMER'S INSURANCE. FotoKem shall have the right, without liability to Customer
          or any third party, to refuse to perform any Service which FotoKem, in its sole discretion, believes could actually
          or potentially (a) expose FotoKem to a claim, or (b) expose FotoKem to public criticism, scorn, contempt or disdain,
          whether or not justified; or (c) be found to be, or expose FotoKem to an accusation or allegation that it is, in
          any way unlawful, pornographic, obscene, harassing, defamatory, disparaging, degrading, or in violation of the
          rights of any third party. FotoKem shall not have any obligation to make any such determination. The existence
          or absence of such determination is not any express or implied assurance or assertion to Customer from FotoKem
          with regard to such matters. However, if FotoKem determines that any such risk exists, FotoKem shall have the right,
          without liability on its part, to stop further Services entirely or in part.
        </p>
        <h3>3. Transportation </h3>
        <p>
          Except with respect to items of Equipment shipped by FotoKem to Customer, Customer shall be responsible to pick-up and ship
          Equipment and return Equipment to the FotoKem rental facility specified in the Transaction Documents. Customer
          shall be responsible for costs of transportation and risk of loss of Equipment in transit. If Customer requests,
          FotoKem may elect to ship Equipment to Customer, with any or all charges to be paid in advance, or collect, or
          otherwise, as FotoKem elects, and all freight and carrier insurance charges shall be paid in advance by Customer.
          Return shipments of Equipment by Customer to FotoKem shall be shipped prepaid, including freight and carrier insurance
          charges. Customer agrees that the effect of transfer of possession of Equipment to Customer or any agent of Customer
          or a shipping company engaged to transport Equipment to Customer shall be deemed to constitute Customer's acceptance
          and agreement to this Agreement, regardless of whether this Agreement was actually signed by or on behalf of Customer;
          provided, that the foregoing shall not impair any prior acceptance and agreement.
        </p>
        <h3>4. Assumption of Liability</h3>
        <p>
          CUSTOMER ASSUMES ALL LIABILITY UNDER COPYRIGHT, OTHER INTELLECTUAL PROPERTY LAWS AND OTHER LAWS, ARISING OUT OF THE PERFORMANCE
          OR NONPERFORMANCE BY FOTOKEM OF ANY SERVICES FOR CUSTOMER, ALSO INCLUDING WITHOUT LIMITATION ANY CLAIMED LIBEL,
          SLANDER, DEFAMATION, DISPARAGEMENT, INVASION OF PRIVACY, MISAPPROPRIATION OF PERSONALITY OR INFRINGEMENT OF PATENT,
          COPYRIGHT, TRADEMARK, TRADE NAME, TRADE SECRET OR OTHER PROPRIETARY RIGHT.
        </p>
        <h3>5. Data Hosting Services</h3>
        <p>
          During the Service Period for Data Hosting Services, FotoKem grants Customer and Customer's authorized users the right to
          access and use the System to receive and store Customer Data. The parties acknowledge that, as between the parties,
          all rights, title and interest in Customer Data belong to Customer and all rights, title and interest in the System
          belong to FotoKem. Customer and Customer's authorized users shall use the System only for Customer's own internal
          purposes and in accordance with any documents, terms or usage restrictions provided by FotoKem from time to time.
          Customer shall not sublicense or otherwise provide or permit access to the System by any third party. FOTOKEM SHALL
          NOT HAVE ANY RESPONSIBILITY FOR, AND CUSTOMER SHALL TAKE ALL APPROPRIATE ACTION TO MAINTAIN AND PROTECT, CUSTOMER'S
          USER ID's AND PASSWORDS. CUSTOMER SHALL BE LIABLE FOR ANY USE OR SECURITY BREACH ATTRIBUTABLE OR TRACEABLE TO CUSTOMER'S
          USER ID's OR TO CUSTOMER'S ACTS OR OMISSIONS OR USE OF THE SYSTEM. CUSTOMER SHALL BE RESPONSIBLE FOR ANY ILLEGAL
          USE OF THE SYSTEM TRACABLE TO CUSTOMER'S USER ID's AND MUST ASSIST FOTOKEM IN LOCATING THE ERRANT USER(S) AND PAY
          ALL FOTOKEM'S COSTS OF ADDRESSING SUCH ILLEGAL USE. FOTOKEM SHALL HAVE NO LIABILITY FOR ANY USE OR SECURITY BREACH
          ATTRIBUTABLE OR TRACEABLE TO CUSTOMER'S USER ID's OR UNAUTHORIZED ACCESS TO OR USE OF THE SYSTEM BY ANY ONE OR
          MORE PERSONS. FOTOKEM SHALL HAVE THE RIGHT WITHOUT LIABILITY, TO SUSPEND OR TERMINATE ACCESS TO THE SYSTEM AT ANY
          TIME WITHOUT NOTICE. On termination of Data Hosting Services, unless due to breach by Customer, FotoKem shall be
          willing to reasonably work with Customer, at Customer's expense, to transition Customer Data to an alternate system
          or media.
        </p>
        <h3>6. Data Storage Services </h3>
        <p>
          Customer acknowledges responsibility to purge all Customer data from rental Equipment system storage prior to return of Equipment
          to FotoKem. After return of Equipment to FotoKem, FotoKem anticipates purging any remaining Customer Data from
          Equipment system storage. FOTOKEM SHALL NOT BE LIABLE FOR DELAY IN PURGING DATA, INCOMPLETE PURGING, OR FAILURE
          TO PURGE, EVEN IF SOME OR ALL DATA REMAINS ON EQUIPMENT AT TIME OF SUBSEQUENT RENTAL TO OTHER RENTAL CUSTOMER.
          If Customer requests that FotoKem store Customer Data for a period of time following the return of rental Equipment,
          FotoKem shall charge Customer short-term data storage charges at FotoKem's then current rates, which may change
          from time to time. As of March 1, 2013 the rate was fifty cents ($ .50) per Gigabyte of data, per day. Should Customer
          Contract with FotoKem for long-term data archiving Services, Customer acknowledges its responsibility to maintain
          duplicate copies of Archival Data in alternate locations to ensure proper back-up. Customer also acknowledges Customer's
          responsibility to maintain any insurance deemed necessary on Archived Data. IN NO EVENT, EVEN IF IN BREACH OF THIS
          AGREEMENT, SHALL FOTOKEM BE LIABLE FOR DELETION, LOSS, DESTRUCTION, CORRUPTION, RELEASE OF, EXPOSURE OF, DISCLOSURE
          OF OR OTHER MISUSE OF, OR DELAY IN ABILITY TO ACCESS CUSTOMER DATA RECEIVED, RECORDED OR STORED IN EQUIPMENT, OR
          STORED OR ARCHVIVED BY FOTOKEM, WHETHER OR NOT AT CUSTOMER REQUEST.
        </p>
        <h3>7. System Use </h3>
        <p>
          In connection with accessing and using the System, Customer agrees: (a) not to use, or allow use of, the System or Data Hosting
          Services in contravention of any federal, state, local, foreign or other law or rules; and (b) not to introduce
          into the System any code, routine or software not specifically authorized, in writing, by FotoKem, or collect information
          about the System or its users. Customer agrees not to modify, adapt or make derivative works of all or any part
          of the System or reverse engineer all or any part of the System, or attempt to do so. Customer assumes full responsibility
          for the integrity of Customer Data, and to ensure that Customer computer(s) and/or other device(s) interfacing
          with the System protect, prevent and/or counteract computer viruses, trojan horses, malware, spyware or other problems
          passed from or through one device to another. Customer will be responsible to cover the cost of repair, replacement
          or correction of any such problem introduced to the Equipment and/or System as a result of Customer's use.
        </p>
        <h3>8. Risk of Unauthorized Access </h3>
        <p>
          Customer acknowledges that despite efforts, if any, that FotoKem may make seeking to inhibit unauthorized access to the System
          and Customer Data, risk of unauthorized access exist. FOTOKEM SHALL HAVE NO OBLIGATION TO SUCCEED IN PREVENTING
          UNAUTHORIZED ACCESS OR PUBLICATION OF CUSTOMER DATA. ANY EFFORTS FOTOKEM MAKES, OR ATTEMPTS, TO PREVENT UNAUTHORIZED
          ACCESS SHALL BE DEEMED TO BE ONLY FOR FOTOKEM'S OWN BENEFIT TO ACCOMPLISH FOTOKEM'S BUSINESS PURPOSES AND SHALL
          NOT BE FOR THE BENEFIT OF CUSTOMER AND SHALL NOT IMPLY OR IMPOSE AN OBLIGATION TO HAVE PERFORMED SUCH EFFORTS PROPERLY
          OR TO ANY STANDARD OF CARE, OR TO UNDERTAKE OR IMPLEMENT OTHER OR FURTHER EFFORTS. Customer also acknowledges that
          unencrypted transmissions through the Internet are inherently unsecure, and assumes all risks associated with any
          transmission of data via the Internet, regardless of the lack of, or use of, appropriate security measures. IN
          NO EVENT, SHALL FOTOKEM BE LIABLE FOR UNATHORIZED ACCESS TO CUSTOMER DATA VIA ANY INTERNET PROTOCOL (IP) DELIVERY;
          (a) FOR INTENTIONAL OR ACCIDENTAL OR OTHER LAWFUL OR UNLAWFUL ACT(S) OF CRACKER(S) OR HACKER(S) OR OTHER UNAUTHORIZED
          ACCESS; (b) FOR USE OR MISUSE OF CUSTOMER DATA BY UNAUTHORIZED PARTIES; (c) FOR LOST OR CORRUPTED OR DESTROYED
          CUSTOMER DATA; (d), FOR DOWNTIME OR SLOWDOWN OF SERVICE; (e) FOR RELEASE, EXPOSURE OR COMPROMISE OF CONFIDENTIAL
          DATA; (f) FOR INABILITY TO ACCESS OR DELAY IN ACCESSING THE SYSTEM WHETHER VIA IP OR OTHERWISE.
        </p>
        <h3>9. Risk of Data Loss</h3>
        <p>
          Customer acknowledges that the intrinsic value of the Customer Data exceeds and bears no relationship to the nature of this
          Agreement, and thus FOTOKEM, WHETHER OR NOT IN ACCORD WITH GENERAL PRACTICE AND INDUSTRY CUSTOM, SHALL HAVE NO
          RESPONSIBILITY FOR USE, MISUSE, UNAUTHORIZED ACCESS, CORRUPTION, LOSS, DAMAGE, DESTRUCTION, RELEASE OR PUBLICATION
          OF CUSTOMER DATA FROM ANY CAUSE WHATSOEVER, INCLUDING WITHOUT LIMITATION, CULPABLE ACT OR OMISSION OF FOTOKEM PERSONNEL,
          ERROR, OMISSION OR FAILURE IN, OR THE UNAVAILABILITY OR INTERRUPTION OF, THE EQUIPMENT, ACTION OF OUTSIDE PARTIES,
          OR FROM CUSTOMER'S USE OF THE EQUIPMENT OR SYSTEM. WITHOUT LIMITING THE GENERALITY OF THE PRECEDING SENTENCE, FOTOKEM
          SHALL NOT BE LIABLE FOR ANY DAMAGE OR COST INCURRED IN THE FORM OF OR AS THE RESULT OF LOST CUSTOMER DATA RESULTING
          IN DAMAGE TO OR LIABILITY OF CUSTOMER TO OTHER PERSON OR ENTITY, LOST PROFIT OR LOST REVENUE, COST OF RECOVERING
          DATA, COST OF SUBSTITUTE DATA OR COVER, CLAIM BY THIRD PARTIES, OR OTHER SIMILAR COSTS RELATED TO POTENTIAL LOSS
          OF DATA, IN THE USE OF THE EQUIPMENT OR SYSTEM.
        </p>
        <h3>10. Risks of Equipment Loss</h3>
        <p>
          Customer assumes all risk of loss of Equipment, whether or not covered by Customer insurance, during the "Risk Period." The
          Risk Period starts when Equipment leaves FotoKem premises and ends as to items of Equipment when FotoKem acknowledges
          receipt of those items. Customer's responsibility shall include, but not be limited to, risk of loss of Equipment
          while in transit as well as at all other locations during the Risk Period. Customer shall, at Customer's cost and
          expense, during the entire Risk Period, keep and maintain the Equipment in at least as good condition and repair
          as when the item left FotoKem's premises. If any item of Equipment is destroyed, damaged, lost or stolen during
          the Risk Period, Customer shall be liable to FotoKem for (a) the repair cost (if FotoKem in its sole discretion
          deems it economically feasible to repair damaged Equipment) or (b) the full replacement cost, if FotoKem's remedy
          under the foregoing subpart (a) is inapplicable, in each case without adjustment for betterment or depreciation
          of that Equipment. The replacement or repair cost of the Equipment shall be determined by the actual cost incurred
          by FotoKem to replace or repair the Equipment. Regardless of whether covered by insurance, Customer shall be responsible
          for the daily rental of each item of destroyed, damaged, lost or stolen Equipment and the Rental Period shall continue
          with respect to each such item of Equipment until the damaged Equipment has been repaired (if FotoKem deems repair
          to be feasible) or the destroyed, damaged, lost or stolen equipment has been replaced. If FotoKem supplies a replacement
          item for any Equipment which is destroyed, damaged, lost or stolen, Customer shall also pay (at FotoKem discretion)
          rent on the replacement item from the date it leaves FotoKem premises, through the end of the Rental Period.
        </p>
        <h3>11. Customer Representation and Warranty</h3>
        <p>
          Customer represents and warrants that Customer is the sole owner, or the duly authorized representative of all owners, of
          the Customer Data used with the Equipment or System and all intellectual property contained in or represented by
          the Customer Data and that Customer's use thereof shall not involve any libel, slander, defamation, disparagement,
          invasion of privacy, misappropriation of personality or infringement of patent, copyright, trademark, trade name,
          trade secret or other violation of law or right of any person or entity.
        </p>
        <h3>12. Additional Disclaimers </h3>
        <p>
          Customer agrees that the Equipment and/or System is not a consumer good for purpose of federal or state warranty laws. FOTOKEM
          MAKES NO WARRANTY, EXPRESS OR IMPLIED, WITH RESPECT TO THE EQUIPMENT, SYSTEM, OR ANY SERVICES PROVIDED TO CUSTOMER,
          INCLUDING BUT NOT LIMITED TO ANY EXPRESS OR IMPLIED WARRANTY OR REPRESENTATION AS TO PERFORMANCE, DESIGN, MERCHANTABILITY,
          SUITABILITY, SECURITY, RELIABILITY OR FITNESS FOR PARTICULAR PURPOSE OF THE EQUIPMENT, SYSTEM, OR SERVICES, OR
          THAT THE SYSTEM WILL OPERATE FREE OF DISRUPTION OR ERROR. THERE ARE NO WARRANTIES THAT EXTEND BEYOND THE DESCRIPTION
          ON THE FACE HEREOF.
        </p>
        <h3>13. Liability Limitations</h3>
        <p>
          FOTOKEM SHALL NOT BE LIABLE TO CUSTOMER, WHETHER IN CONTRACT, TORT, UNDER ANY WARRANTY, OR OTHERWISE, FOR INCIDENTAL, CONSEQUENTIAL,
          OR SPECIAL DAMAGES. UNDER NO CIRCUMSTANCE SHALL FOTOKEM'S LIABILITY OR CUSTOMER'S REMEDY FOR DAMAGES OR OTHER CLAIM
          AGAINST FOTOKEM (WHETHER RELATED TO THE EQUIPMENT, SYSTEM OR ANY SERVICES) EXCEED THE AMOUNT OF RENT/LEASE AND/OR
          SERVICE FEES ACTUALLY PAID TO FOTOKEM BY CUSTOMER UNDER THIS AGREEMENT FOR THE PARTICULAR PORTION OF THE EQUIPMENT,
          SYSTEM OR SERVICES AND FOR THE PARTICULAR TIME PERIOD INVOLVED. FOTOKEM SHALL NOT BE RESPONSIBLE FOR DELAY OR FAILURE
          IN PERFORMANCE RESULTING FROM ACTS OR CIRCUMSTANCES BEYOND FOTOKEM'S CONTROL, INCLUDING WITHOUT LIMITATION ACT
          OF GOD, STRIKE OR OTHER LABOR DISPUTE, RIOT, ACT OF WAR, TERRORISM, MALFUNCTION OF PORTIONS OF THE INTERNET OR
          ANOTHER NETWORK, GOVERNMENT REGULATION, COMMUNICATION LINE FAILURE, POWER FAILURE, EQUIPMENT FAILURE, FIRE, WEATHER,
          EARTHQUAKE OR OTHER DISASTER, ACT OF THIRD PARTIES INCLUDING BUT NOT LIMITED TO ANY HACKERS OR ANY OTHER INCIDENT,
          CONDITION, CIRCUMSTANCE OR EVENT THAT DISRUPTS FOTOKEM'S ABILITY TO PERFORM.
        </p>
        <h3>14. Indemnification</h3>
        <p>
          Customer shall use the Equipment and/or System at Customer's own risk and expense. Customer assumes full and sole responsibility
          and liability for injuries (including without limitation death) to any person or property resulting from the use
          or possession of the Equipment. FotoKem shall not be liable for, and Customer shall indemnify, defend and hold
          FotoKem and FotoKem's shareholders, directors, officers, affiliated entities, employees, and agents harmless from
          and against, any and all actual threatened claims (whether made by Customer, or third party), and any costs incurred
          or other damages suffered by or threatened against FotoKem, whether in whole or in part, out of or in connection
          with any of the following: (a) Customer's Contract, possession, operation or other use of the Equipment or the
          condition of the Equipment (including without limitation latent defects) or of any instructions or lack thereof;
          (b) performance of the Services, including without limitation (i) claims regarding acts or omissions of FotoKem
          personnel (which, Customer acknowledges, shall be deemed to be under Customer's direction and control as provided
          in Sections 2 &amp; 4), and (ii) claims for personal injury and property damage arising in connection with any
          Services; (c) negligent or willful act or omission of Customer or Customer's personnel, whether in connection with
          use or misuse of the System or creation or use of any Customer Data or otherwise, also including without limitation
          any related property damage or personal injury (also including but not limited to libel, slander, defamation, invasion,
          of privacy, misappropriation of personality or infringement of patent, copyright, trademark, trade name, trade
          secret or other proprietary right, whether of FotoKem or any third party); (d) Security breach or other activity
          associated with Customer's user IDs and passwords for the System; (e) misuse of System, including without limitation
          as provided in Section 7; (f) infringement of any third party intellectual property right; (g) misuse, loss, damage
          or destruction of Customer Data as provided in Section 15; (h) breach by Customer of any covenant, warranty or
          representation; and (i) protection or enforcement by FotoKem of rights under the Contract.
        </p>
        <h3>15. Limit of Liability</h3>
        <p>
          FOTOKEM SHALL NOT BE LIABLE TO CUSTOMER OR TO ANY OTHER PERSON OR ENTITY FOR ANY COST, EXPENSE, LOSS OF PROFIT, LOSS OF BUSINESS,
          INTERRUPTION OF BUSINESS, OR FOR OTHER DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR OTHER DAMAGE, COST,
          LOSS OR EXPENSE OF ANY KIND, WHETHER UNDER THIS AGREEMENT OR OTHERWISE, EVEN IF FOTOKEM WAS ADVISED OF THE POSSIBILITY
          OF THAT DAMAGE, COST, LOSS OR EXPENSE. FOTOKEM SHALL NOT BE LIABLE FOR ANY REPRESENTATION OR WARRANTY MADE BY CUSTOMER,
          ANY AGENT OF CUSTOMER OR ANY OTHER PERSON OR ENTITY.
        </p>
        <h3>16. Equipment Use and Operation</h3>
        <p>
          Customer shall allow the Equipment and/or System to be used only by duly qualified technicians employed by Customer and only
          for the usual uses of the Equipment or System. FotoKem makes no warranty or representation as to capabilities or
          any other qualification or aspect of any technician or service provider, even if identified or suggested by FotoKem.
          Customer shall investigate and make Customer's own determination whether to engage the services of any technician
          or service provider. FotoKem shall not be responsible in any way for any aspect of services performed by, or failures
          of performance by, any technician or service provider, even if suggested or identified by FotoKem. Customer shall
          keep the Equipment in Customer's sole custody and shall not permit the Equipment to be used in violation of any
          law or of rights of any individual or entity. Customer shall not install or uninstall software, modify or service
          the Equipment without the written consent of FotoKem. Customer shall not permit the Equipment to be (a) used in
          any location deemed inappropriate by FotoKem, or (b) removed from the contiguous United States (the 48 contiguous
          states) without FotoKem's prior written consent, which consent may be granted or withheld in FotoKem's sole discretion.
        </p>
        <h3>17. Inspection </h3>
        <p>
          Customer agrees to grant FotoKem representatives physical access to Equipment for inspection upon FotoKem request, regardless
          of location. At FotoKem's discretion, Customer agrees to facilitate and allow remote internet enabled system inspection
          while Equipment is in use, regardless of any question of confidentiality by Customer.
        </p>
        <h3>18. Ownership Notices </h3>
        <p>
          Customer acknowledges that FotoKem is the sole owner of the Equipment. Customer shall not remove any tag or nameplate on
          the Equipment indicating FotoKem's ownership. Customer shall keep the Equipment free of all pledges, levies, liens
          and encumbrances not created by FotoKem. Customer shall not permit any person or entity to file or publish a UCC-1
          or other financing statement purporting to encompass all or any part of the Equipment. FotoKem shall have the right,
          and Customer hereby authorizes FotoKem to file, publish and post UCC-1's, other forms of financing statements and
          other public notices indicating FotoKem's ownership of the Equipment.
        </p>
        <h3>19. Insurance </h3>
        <p>
          Customer shall obtain and maintain insurance coverage from one or more insurance carriers, as FotoKem approves, and deems
          adequate to insure; (a) the replacement value of the Equipment; and (b) for injuries to persons or property that
          may result from Services or use or possession of the Equipment, but in no event shall Customer obtain less than:
          (i) Commercial General Liability Coverage containing broad form, occurrence based coverage for bodily injury, personal
          injury and property damage claims arising out of Customer's operations, naming Foto-Kem Industries, Inc. as insured,
          with limits of one million dollars ($1,000,000) per occurrence; and (ii) Property Insurance Coverage for all Equipment
          rented or leased by Customer including without limitation under the Contract, coverage for Equipment in transit,
          written on a replacement cost basis without deduction for depreciation, with limits of one million dollars ($1,000,000),
          naming Foto-Kem Industries, Inc. as "Loss Payee." Insurance shall not contain any "unattended or unlocked vehicle"
          exclusion. All insurance coverage required by this Section shall contain a waiver of subrogation in favor of FotoKem,
          and shall be written in U.S. dollars by a United States carrier with a financial strength rating by A.M. Best Company
          (or comparable insurance rating agency) of not less than B++ and, if FotoKem has consented to the Equipment leaving
          the United States (which consent is not effective unless given in writing), shall be written on a world-wide basis,
          including coverage for transit. The policy shall state that the insurer shall provide FotoKem with at least thirty
          (30) days advance written notice of cancellation or reduction or increase in coverage or other material change.
          (iii) Nothing in this Section 18 is intended to imply that the coverages required are in any sense sufficient for
          Customer's purposes.
        </p>

        <h3>20. Proof of Insurance</h3>
        <p>
          Customer shall furnish proof satisfactory to FotoKem of all coverage required under Section 19 prior to receiving any Equipment.
          However delivery of Equipment without FotoKem's receipt of such proof is not a waiver of the requirements of Section
          19. Proof of coverage shall be in at least the form of a Certificate of Insurance issued by Customer's Insurance
          Agent with respect to each policy and containing the following information and otherwise meeting the following
          requirements: (a) the Certificate Holder shall be Foto-Kem Industries, Inc. 2801 W. Alameda Ave., Burbank, CA 91505;
          (b) Foto-Kem Industries, Inc. shall be named on the liability coverage required in Section 19 and as Loss Payee
          in the property coverage required under Section 19 and shall be set forth in the Certificate; (c) the name, address
          and phone number of the Insurance Agent shall be stated in the Certificate; (d) Customer shall appear in the Certificate
          as Named Insured; (e) the Insurance Company affording coverage must be identified in each Certificate; (f) the
          deductible, if any, shall be indicated (but Customer is responsible for the deductible portion of the loss and
          any portion of the loss in excess of the applicable limits); (g) the Certificate shall specify that coverage will
          apply until the Equipment is returned to FotoKem in proper working order; and (h) the Certificate shall be signed
          by an authorized representative or agent of the Insurance Company.
        </p>
        <h3>21. Customer to Insure Customer Data</h3>
        <p>
          CUSTOMER ACKNOWLEDGES AND AGREES THAT FOTOKEM IS NOT OBLIGATED TO OBTAIN INSURANCE FOR OR ON BEHALF OF CUSTOMER, AND/OR CUSTOMER
          DATA OR THE EQUIPMENT AND THAT CUSTOMER SHALL BE SOLELY RESPONSIBLE TO INSURE AGAINST ALL INSURABLE RISKS INCLUDING
          WITHOUT LIMITATION DAMAGE TO OR DESTRUCTION OF CUSTOMER DATA AND/OR EQUIPMENT, WHETHER CAUSED BY CULPABLE ACT OR
          OMISSION OF FOTOKEM OR FOTOKEM PERSONNEL OR OTHERWISE. FOTOKEM SHALL IN NO EVENT BE LIABLE FOR ANY LOSS OR DAMAGE,
          WHETHER OR NOT SUCH LOSS OR DAMAGE WAS, OR COULD HAVE BEEN, COVERED BY INSURANCE. FOTOKEM SHALL IN NO EVENT BE
          LIABLE FOR ANY LOSS OR DAMAGE RESULTING FROM LOST CUSTOMER DATA.
        </p>
        <h3>22. Repossession by FotoKem</h3>
        <p>
          If Customer fails to pay any Equipment rent or lease payment when due, or otherwise breaches any term, covenant or condition,
          or if Customer is or becomes subject to any proceeding which FotoKem considers may result in seizure of the Equipment,
          or if a proceeding in bankruptcy, receivership or insolvency is instituted by or against Customer or any property
          of Customer, or if for other reason FotoKem becomes insecure with regard to Lessee's possession or use of the Equipment,
          or on or following the end of the Rental/Lease Period, then FotoKem shall in any such case have the right to enter
          the premises where the Equipment is or is believed to be located and retake possession of the Equipment, with or
          without notice to Customer, and in each case without liability to Customer for any damages incurred by Customer
          as a result of the action. By taking possession of the Equipment pursuant to this Section, FotoKem shall be deemed
          to have ended the Rental Lease Period (if it had not already ended) and shall be deemed not to have waived or otherwise
          prejudiced any other right, claim or remedy that FotoKem has or may have against Customer.
        </p>
        <h3>23. Confidentiality</h3>
        <p>
          Customer shall not disclose the Bid terms and pricing or the discussions and negotiations that occurred between FotoKem and
          Customer regarding the Contract to any third party, unless disclosure is required by law, and shall not disparage
          FotoKem, the Services, the Equipment, or any of FotoKem's directors, officers or employees or FotoKem's successors
          or assigns. FotoKem acknowledges that some Customer Data may be confidential and proprietary to Customer. Any breach
          of confidentiality by FotoKem shall be subject to the limitations of remedies in the Contract.
        </p>
        <h3>24. Final Agreement</h3>
        <p>
          The Contract represented by the Transaction Documents is the final, complete and exclusive expression of the agreement between
          Customer and FotoKem with respect to its subject matter. In any conflict between any term or condition of this
          Equipment Rental and Services Agreement and any other Transaction Document, this Equipment Rental and Services
          Agreement shall control, unless the Transaction Documents include an Override Agreement, in which event the Override
          Agreement shall control to the extent of the conflict between any term or condition of the Override Agreement and
          any term or condition of any other Transaction Document. No covenant, warranty, representation or other term and
          condition, whether express or implied (including without limitation any representations or warranties made by any
          agent or representative of FotoKem or Customer), which are not set forth in writing in the Contract shall bind
          FotoKem. The Contract cannot be modified except by the written agreement of FotoKem and Customer. The parties agree
          that the Contract has been entered into in the State of California and shall be interpreted under and governed
          by the internal laws of the State of California applicable to contracts made and to be performed entirely within
          California, without reference to or regard for any conflicts of law principles, notwithstanding that one or both
          parties to this Agreement may be domiciled or the Equipment may be located in another state. Customer shall not
          have any right to assign any rights to delegate any of its obligations under the Contract and any attempted or
          purported assignment or delegation by Customer shall be deemed to be void and of no effect. No third party is contemplated
          or intended to have any rights under the Contract, except to the extent of any rights that are separately and expressly
          created by FotoKem for the benefit of a third party. As exceptions: (a) with regard to the indemnification provisions
          in this Agreement, the various persons and entities as to whom this Agreement provides for indemnification, shall
          be deemed to be third party beneficiaries with the right to enforce the indemnification, defense and hold harmless
          provisions even though such persons have not signed this Agreement; and (b) provisions in this Agreement for the
          benefit of FotoKem, such as disclaimers of warranties and limitations of liability, shall be deemed to encompass
          and benefit FotoKem and FotoKem's shareholders, directors, officers, employees, agents and affiliated entities.
          The provisions of this Agreement reflect a balancing of economic interests and shall be interpreted and construed
          according to their fair meanings and not strictly for or against any party.
        </p>
        <h3>25. Acceptance </h3>
        <p>
          The Agreement will become binding on Customer's signing the Bid included as one of the Transaction Documents. By indicating
          acceptance in the foregoing manner, Customer agrees, represents and warrants that Customer has read, understands
          and agrees to be bound by the Terms and Conditions of the Contract between Customer and FotoKem; provided that
          without impairing or otherwise affecting any earlier acceptance, Customer's acceptance of any goods or services
          provided pursuant to the Contract or transfer of possession of Equipment to Customer or any agent of Customer or
          a shipping company engaged to transport Equipment to Customer as provided in Section 3, also constitutes Customer's
          acceptance of the Contract, and Customer agrees to be bound by the Terms and Conditions of the Agreement.
        </p>
      </div>

      <div
        v-if="currentSection === 'network'"
        class="network"
      >
        <h2>FOTOKEM TERMS &amp; CONDITIONS FOR NETWORK SERVICES</h2>

        <p>
          These Terms and Conditions govern "Customer" use of Foto-Kem Industries, Inc.'s ("FotoKem") fiber optic and Internet connectivity
          network ("Services"). By using FotoKem Services, Customer accepts and agrees to be bound by the Terms and Conditions
          of the following "Agreement."
        </p>
        <h3>1. DEFINITIONS </h3>
        <p>
          (a)
          <strong>"We"</strong> or
          <strong>"us"</strong> or
          <strong>"FotoKem"</strong> means Foto-Kem Industries, Inc. or its affiliated or dba entities.
          <br>(b)
          <strong>"Customer"</strong> means the party entering into the Agreement as well as all employees and independent contractors
          working for Customer.
          <br>(c)
          <strong>"Third Party"</strong> means any person or entity other than Customer or FotoKem.
          <br>(d)
          <strong>"FotoKem Network"</strong> means FotoKem's fiber optic line(s) or internet connectivity or wired or wireless access
          points, switches, radio and/or microwave connections.
          <br>(e)
          <strong>"Facilities"</strong> means FotoKem supplied devices and/or systems used to deliver Services, including but not
          limited to terminal and other equipment, wires, lines, circuits, ports, routers, switches, channel service units,
          data service units, cabinets, racks and the like. Facilities shall not include devices sold to Customer by FotoKem
          and paid for by Customer, or owned by Customer or by a Third Party.
          <br>(f)
          <strong>"Services"</strong> means services to be supplied by FotoKem to Customer, including but not limited to allowing,
          Customer access to the FotoKem Network and Internet connectivity.
          <br>(g)
          <strong>"Site"</strong> means location(s) where Services are to be provided.
          <br>(h)
          <strong>"Licensed Software"</strong> means computer software, in object code format, the use of which is required for use
          of Services ordered by Customer.
          <br>(i)
          <strong>"FotoKem Equipment"</strong> means equipment (if any) supplied by or on behalf of FotoKem to Customer or placed
          at or on the Site for the purposes of providing Services.
          <br>(j)
          <strong>"Customer Equipment"</strong> means all equipment other than FotoKem Equipment.
          <br>(k)
          <strong>"Planned Outage"</strong> means loss of Services which occurs due to repair, maintenance and servicing at a specified
          times in which FotoKem has notified Customer in advance.
          <br>(l)
          <strong>"Intellectual Property Rights"</strong> means patents, trade-marks, service marks, copyrights, trade names, know-how,
          licenses or consents in relation to any of these.
          <br>(m)
          <strong>"Confidential Information"</strong> means business information that is and should reasonably be recognized as being
          confidential, and may include, (but not be limited to), details of network infrastructure and design, third party
          service providers, addresses, passwords, etc. Confidential Information may also include, specifications, drawings,
          data, documentation, manuals, instructions, customer lists, business plans, developments, trade secrets, technical
          or other expertise and know-how, licensed software, source code, updates, and other non-public information regarding
          the business of a party, provided to a party by the other party, where such information is conspicuously and reasonably
          marked or otherwise communicated or considered as being "Confidential."
        </p>
        <h3>2. ACCESS TO FOTOKEM NETWORK </h3>
        <p>
          FotoKem contracted with one or more vendors to install network connectivity at FotoKem Facilities. FotoKem agrees to provide
          Customer access to that network connectivity assuming Customer acceptance and adherence of and to these Terms &amp;
          Conditions, and Customer's responsible use of Services.
        </p>
        <h3>3. CUSTOMER EQUIPMENT </h3>
        <p>
          Aside from FotoKem Equipment, if any, Customer shall be responsible to obtain and have in place all equipment needed to access
          the FotoKem Network. FotoKem shall have no obligation for securing or maintaining Customer Equipment, and no liability
          for Customer's inability to access the FotoKem Network due to lack of or failure of Customer Equipment, or where
          the use of additional or other Customer Equipment would enable such access.
        </p>
        <h3>4. WIRELESS ACCESS </h3>
        <p>
          If applicable, Wireless access points may be provided as a courtesy, and not as an obligation. Customer acknowledges these
          are subject to, various forms of interference and disruption resulting in Customer's inability to maintain connectivity
          to the FotoKem Network. Customer shall maintain access to wired network ports, to reduce the effects of disruption
          in wireless access, and a redundant network connection that FotoKem or Customer can use for remote troubleshooting
          and /or as a temporary internet connection until restoration of connectivity to the FotoKem Network can be completed.
          In no event will FotoKem be responsible for any disruption in connectivity caused by Wireless Access points.
        </p>
        <h3>5. REPORTING SERVICE ISSUES</h3>
        <p>
          Customer shall report service issues to FotoKem as soon as possible. FotoKem may but is not required to pass-on to Customer
          any credit, rebate or other consideration FotoKem may receive from a vendor on account of defect in service.
        </p>
        <h3>6. CHANGES </h3>
        <p>
          FotoKem reserves the right at any time to make changes or additions to, or to replace any part of, the FotoKem Network, equipment,
          specifications, access control or other aspect of the equipment or Services, whether for safety, seeking to improve
          service, or to lower cost, or to conform to statutory requirements or requirements of regulatory bodies or internet
          governing body or as FotoKem otherwise deems desirable. FotoKem may but is not required to make an effort to contact
          Customer to agree to timing for implementing changes, but does not assure success in doing so.
        </p>
        <h3>7. PERMITS, CONSENTS </h3>
        <p>
          Customer shall, at Customer's cost, obtain all permits, permissions, licenses, consents, or registrations deemed necessary
          by FotoKem, to deliver, install, and maintain at Customer's Site any FotoKem Equipment required to facilitate Services.
        </p>
        <h3>8. PROVISIONS REGARDING CUSTOMER USE OF SERVICES</h3>
        <p>
          (a) Customer shall use the Services according to these terms, and all other operating guides and instructions FotoKem notifies
          Customer of.
          <br>(b) Customer shall not use the Services or permit the Services to be used: (i) for any unlawful, fraudulent or
          unauthorized act, purpose or activity; (ii) to transmit unlawful, defamatory, offensive, threatening, abusive,
          obscene or menacing material; (iii) in any way that violates or infringes Intellectual Property, confidentiality
          or other right of any person, firm or entity; (iv) in any way that allows or enables unauthorized access to the
          Services or the FotoKem Network; (v) in any way that may cause interference, corruption, harm or loss of control
          to any computer, network or system utilizing the Services, (vi) in violation of any law or regulation, or in a
          way that places FotoKem in violation of any law or regulation; (vii) for unsolicited bulk communication or advertising
          to multiple recipients; or (viii) in violation of these terms of use, or general rule, policy or law covering a
          network or facility through which data may pass in Customer's use of the Services.
          <br>(c) Customer shall not: (i) let any Third Party use or access the FotoKem Network; (ii) use the FotoKem Network
          other than to access the Services for purposes specified in these terms; (iii) use the FotoKem Network in any way
          that in FotoKem's opinion may bring the Services or FotoKem into disrepute; and/or (iv) make any representation
          or warranty to a Third Party as to quality, availability, or fitness for a particular purpose, of the Services.
          <br>(d) Following notice from FotoKem, an intellectual property owner, government authority or law enforcement agency
          that particular Internet sites, domain names, or newsgroups are or may be carrying Illegal material or unlawfully
          carrying material, Customer shall take all necessary steps to prevent access to such sites, domains or newsgroups
          by Customer or Customer employees, and shall cooperate fully with any FotoKem investigation in response to such
          notice. Should Customer be found in violation of governing laws, Customer agrees to accept responsibility for any
          and all damages, fines, charges, or expenses associated with such notice and/or investigation.
        </p>
        <h3>9. SUSPENSION OF SERVICE</h3>
        <p>
          (a) FotoKem may (in addition to all other rights) suspend providing Services, with or without prior notice, in whole or in
          part, if (i) required or convenient to comply with an order, instruction or request of, or to cooperate with, a
          government, court, utility service, or other authority; (ii) convenient for FotoKem or its vendor to perform work
          relating to upgrading, repair or maintenance of the Services or FotoKem Network or any part thereof including PoPs,
          servers or any component or part of connectivity; (iii) Customer fails to timely pay any amount due to FotoKem;
          or (iv) Customer breaches any provision of these terms.
          <br>(b) FotoKem may, but is not required, prior to suspension under Section 9(a), or a scheduled service interruption,
          attempt to notify Customer of the timing and/or reason for suspension or interruption of Services.
        </p>
        <h3>10. REMEDY FOR LOSS OF CONNECTIVITY</h3>
        <p>
          (a) As it is the responsibility of FotoKem's Internet provider (not FotoKem) to provide Internet connectivity to FotoKem,
          FotoKem shall not be responsible or liable for interruption or any failure or inability of Customer to access the
          Services. FotoKem's obligation is limited to requesting that its vendor restore connectivity promptly. FotoKem
          strongly recommends that Customer maintain redundant access to Internet connectivity.
          <br>(b) No remedy shall be available to Customer, and Customer waives and releases any claim, for inability to obtain
          Services during a planned outage, interruption or suspension under Section 9, or termination under Section 11.
        </p>
        <h3>11. TERMINATION</h3>
        <p>
          (a) FotoKem shall have the right to terminate Services, with or without notice, if (i) Customer fails to pay FotoKem as and
          when due; (ii) Customer breaches these terms; (iii) a trustee, liquidator, receiver, or administrator is appointed
          over Customer or any part of Customer's assets, Customer becomes insolvent or unable or fails to pay its debts
          as they mature, makes an assignment or arrangement for benefit of creditors, bankruptcy insolvency or other debt
          relief proceedings are instituted by or against Customer, an application is made to a Court for relief from debt
          obligations or to restructure, Customer adopts a resolution or takes other action to effect its winding up or termination
          of its existence, or a Court of competent jurisdiction makes an order or takes any action to that effect; (iv)
          Customer defaults in the performance of or compliance with any agreement with FotoKem and (in case of a remediable
          breach) fails to remedy the breach within three (3) days of notice of breach being given in any form by FotoKem;
          (v) the Services can no longer be provided by FotoKem for any reason whatsoever; or (vi) Customer violates intellectual
          property rights of others, including but not limited to, infringing or misusing a Third Party's trademark, copyright,
          patent or trade secret.
          <br>(b) Customer may terminate the Services by providing FotoKem 30 days' written notice if FotoKem fails in a material
          way to provide the Service. Should there be no enforceable service term as stipulated by contract, Customer may
          terminate Services by providing FotoKem 30 days written notice.
        </p>
        <h3>12. CONSEQUENCES OF TERMINATION</h3>
        <p>
          (a) On termination for any reason Customer shall (i) immediately stop using the Services; (ii) ensure Customer does not mislead
          or represent to others that Customer has any contractual arrangement with FotoKem; (iii) stop use of any trademark
          or other intellectual property belonging to FotoKem (but this Section does not imply that these terms grant, or
          that Customer has, any right to use such trademark or other intellectual property); (iv) destroy or return to FotoKem
          (at FotoKem's option) all material containing FotoKem intellectual property; and (v) return to FotoKem all software,
          hardware and other equipment which FotoKem may have provided to Customer for purposes of using the Services.
        </p>
        <h3>13. PROPERTY RIGHTS</h3>
        <p>
          (a) Customer shall not violate and shall cause its employees to not violate intellectual property rights of any person or
          entity, including, but not limited to, any trademark, patent, copyright or trade secret. Customer shall monitor
          employees' use of the Services sufficiently to comply with the foregoing obligation. Customer shall not use the
          Services or FotoKem's servers to access, bypass, download, stream, share, or do any other act in violation of rights
          of Third Parties. Customer shall not seek to or actually circumvent FotoKem's security procedures and shall abide
          by all requirements imposed by FotoKem.
          <br>(b) All legal and beneficial rights in all FotoKem Equipment and software FotoKem provides to Customer for use
          of the Services shall remain at all times the property of FotoKem or FotoKem's vendor.
          <br>(c) Should FotoKem provide proprietary software applications to Customer for the purpose of accessing Services,
          FotoKem, to the extent of its ability to do so, shall grant Customer a non-exclusive non-transferable license to
          use the software during the term, for the purpose of using the Services, and no other purpose. Customer shall not
          reproduce the software for any purpose, including back-up, without expressed written permission from FotoKem. Customer
          shall not modify, adapt, translate, reverse engineer, decompile or disassemble the software or create any derivative
          work based thereon or merge or include the software with or in any other software.
          <br>(d) Copyrights in all documents, drawings, designs and embodiments of information, including if applicable any
          access codes supplied by FotoKem to Customer shall remain vested in FotoKem or the copyright owner who provided
          these to FotoKem. Customer shall not copy, disclose or use these other than for the purpose for which they were
          supplied, without FotoKem's prior written consent.
          <br>(e) FotoKem shall have the exclusive right to commence, defend, conduct and settle all proceedings involving infringement
          or alleged infringement of FotoKem's Intellectual Property Rights or relating to the validity thereof.
          <br>(f) Customer shall notify FotoKem immediately in writing of any suspected infringement of FotoKem's Intellectual
          Property by Customer or any other person or entity. Customer shall give all reasonable assistance and documentation
          as FotoKem requests.
          <br>(g) Customer acknowledges that all Intellectual Property Rights are and shall remain (as between FotoKem and Customer)
          the property of FotoKem, its vendors, or others.
          <br>(h) Nothing in this Agreement shall act as an assignment by FotoKem to Customer of any copyright, patent, trademark,
          or other Intellectual Property Right belonging to FotoKem.
          <br>(i) Customer shall not use, whether in the course of business or otherwise, in relation to any goods, services,
          advertising or promotion, any registered or unregistered name, mark, design, logo or similar Intellectual Property
          Right of FotoKem, its vendors, or others. By way of illustration, no use may be made by Customer of the name FotoKem
          or any mark, logo or other Intellectual Property Rights without the expressed written permission of FotoKem. All
          Intellectual Property Rights and/or know-how generated or arising in the performance of or as a result of rendering
          the Services shall (to the extent not already vested in FotoKem) vest in and be the property solely of FotoKem.
        </p>
        <h3>14. LIMITATION OF LIABILITY </h3>
        <p>
          FOTOKEM SHALL HAVE NO LIABILITY TO CUSTOMER WHATSOEVER FOR INTERRUPTION OR INABILITY OF CUSTOMER TO ACCESS THE SERVICES,
          OR LOSS OF CONNECTIVITY, INCLUDING BUT NOT LIMITED TO LOSS OF DATA OR PRODUCTIVITY, OTHER THAN THE OBLIGATION TO
          USE REASONABLE EFFORTS IN SEEKING TO REESTABLISH THE CONNECTION. FOTOKEM SHALL HAVE NO LIABILITY OR RESPONSIBILITY
          FOR EQUIPMENT FAILURE, WHETHER SUPPLIED BY FOTOKEM OR CUSTOMER. FOTOKEM MAKES NO WARRANTY, EXPRESS OR IMPLIED AND
          DISCLAIMS ALL IMPLIED WARRANTIES AS TO QUALITY, LEVEL, AMOUNT, FREEDOM FROM OR OTHER ASPECT OF: INTERRUPTION, CONNECTIVITY,
          DELIVERY, CONTINUITY, ERROR, DELAY, QUALITY, FITNESS FOR PURPOSE, SUITABILITY OR PERFORMANCE TO BE PROVIDED, ACCURACY
          OR CONTENT OF INFORMATION PASSING THROUGH ANY NETWORK, UNLESS EXPRESSLY STATED HEREIN. FOTOKEM SHALL NOT BE LIABLE
          TO CUSTOMER IN CONTRACT, TORT OR OTHERWISE, INCLUDING LIABILITY FOR NEGLIGENCE, OR FOR LOSS OF REVENUE, BUSINESS,
          CONTRACTS, ANTICIPATED SAVINGS, OR PROFITS, OR ANY INDIRECT, ECONOMIC OR CONSEQUENTIAL LOSS HOWSOEVER ARISING.
        </p>
        <h3>15. INDEMNITY AND UNDERTAKING </h3>
        <p>
          Customer shall indemnify, defend and hold harmless FotoKem from and against any and all liabilities, claims, costs and demands
          whatsoever (also including attorney's fees, costs, damages, fines, judgments and expenses), arising from claims
          by any Third Party (regardless of cause of action and whether suffered directly or indirectly by FotoKem or others)
          in connection with or relating to the use of the Services by Customer or breach of any term hereof by Customer.
        </p>
        <h3>16. LIQUIDATED DAMAGES </h3>
        <p>
          Customer acknowledges that for each violation of a Third Party's intellectual property rights, FotoKem will have damages
          consisting of but not limited to costs to relay information concerning the claim and take measures to address the
          claim, and loss of reputation and business. Customer shall, in addition to performing the indemnity obligations
          above, pay FotoKem (at FotoKem's discretion), as liquidated damages, the sum of $5,000, on account of such damages.
          Customer acknowledges this liquidated damages provision represents reasonable compensation to FotoKem for the damages
          due to such breach. Nothing in this section 16 shall limit FotoKem's right to obtain injunctive and/or other provisional
          relief against Customer for breach of these terms.
        </p>
        <h3>17. FORCE MAJEURE </h3>
        <p>
          (a) A party shall not be liable to the other for any loss or damage which may be suffered by the other, due to cause beyond
          the party's reasonable control including without limitation act of God, inclement weather, failure or shortage
          of power, flood, earthquake, lightning or fire, strike, lock-out, trade dispute or labor disturbance, act or omission
          of government or other competent authority, other telecommunications operator, war, military operation, acts of
          terrorism, riot, accident, delay or failure in manufacture, production or supply by third parties of equipment
          and/or services, or technical problems which make it impracticable to perform these terms.
          <br>(b) If the delay caused by an event referred to in section 17(a) lasts more than 30 days, either party shall have
          the right, but not the obligation, to terminate the Services by giving written notice to the other party.
        </p>
        <h3>18. GENERAL</h3>
        <p>
          (a)
          <strong>Notices:</strong> Notices hereunder shall be in writing and be given by mail or email to the other party's address
          or other address as may be designated in writing from time to time by the other party. Notice sent by mail shall
          be deemed to be delivered at the earlier of actual receipt or three days after dispatch. Notice sent by email shall
          be conspicuously labeled to call special attention to it and deemed to be delivered the business day after successful
          transmission.
          <br>(b)
          <strong>Assignment:</strong> Customer shall not assign or delegate or purport to assign or delegate any rights or obligations
          hereunder, without FotoKem's prior written consent. FotoKem shall have the right to assign, subcontract or otherwise
          delegate any or all its rights and obligations to any person or entity.
          <br>(c)
          <strong>Modifications:</strong> FotoKem shall have the right by written notice to Customer to modify these terms at any
          time to comply with regulations or other requirement imposed on FotoKem by vendors, government regulations, or
          other authority.
          <br>(d)
          <strong>Delay:</strong> Delay or failure by a party to exercise a right or remedy shall be deemed not to waive the exercise
          of that right or remedy on another occasion, nor shall a single or partial exercise of rights or remedies, preclude
          other or future exercise of them. Remedies hereunder are cumulative and not exclusive of remedies provided by law.
          Any waiver shall be limited to the specific breach waived.
          <br>(e)
          <strong>Severability:</strong> If any provision of these terms shall be found by a court or other authority having jurisdiction
          to be invalid or unenforceable, that determination shall not affect the other provisions of these terms. All provisions
          not affected by the invalidity or unenforceability shall remain in full force and effect.
          <br>(f)
          <strong>Non-Exclusivity:</strong> These terms shall not prejudice any other arrangements or agreements FotoKem may have
          with any person or entity for telecommunications, internet or similar services. Nothing in these terms shall restrict
          FotoKem from providing identical or similar Services to any other person or entity, on any terms.
          <br>(g)
          <strong>Entire Agreement:</strong> Unless otherwise indicated, these terms represent the entire understanding between the
          parties relating to their subject matter and supersede all agreements and representations between the parties,
          whether oral or written, on its subject.
          <br>(h)
          <strong>Headings:</strong> Headings are for convenience only and shall not be used in the construction or interpretation
          of these terms.
          <br>(i)
          <strong>Construction:</strong> In these terms, where the context makes appropriate, the singular includes the plural and
          vice versa. These terms are not intended to benefit or confer any right or benefit on any Third Party.
          <br>(j)
          <strong>Relationship:</strong> The parties are independent and unrelated persons. Nothing herein shall be construed to
          create a partnership, joint venture, agency or similar arrangement or relationship. Neither party shall have power
          or authority to make any commitment for or bind the other party to any obligation. Neither party shall make any
          representation or warranty, express or implied, on behalf of the other party.
          <br>(k)
          <strong>Confidentiality:</strong> Customer acknowledges it may receive Confidential Information belonging to FotoKem which
          may include, but not be limited to, technical, contractual, business and financial information. Customer shall
          hold such information in strict confidence and take all reasonable steps to maintain its confidentiality. These
          restrictions shall not apply to the extent information or material is obtained by Customer from third parties without
          violation of confidentiality or non-disclosure obligation; publicly made available other than through Customer's
          fault; or disclosed to the public generally with prior written approval of FotoKem. Customer shall return all Confidential
          Information in its possession to FotoKem within one week of FotoKem's written request.
          <br>(l)
          <strong>Survival:</strong> Sections 10, 12, 13, 14, 15, 16, 17, 18 and all other provisions which, by nature survive expiration
          or termination, shall continue in effect regardless of termination or expiration of these terms, until performed
          or until by nature, such provisions have expired.
          <br>(m)
          <strong>Governing Law:</strong> These terms shall be interpreted according to the internal laws of the State of California,
          and all applicable federal laws, rules and regulations, without regard for states rules on conflicts of laws. In
          a dispute between the parties, exclusive jurisdiction and venue shall be in the United States District Court for
          the Central District of California or the Superior Court of the State of California for the County of Los Angeles.
          Customer consents to this jurisdiction and venue.
          <br>(n)
          <strong>Schedules:</strong> Any schedules attached to these terms are incorporated herein and shall be deemed an integral
          part thereof Schedule 1 - The Services.
        </p>
      </div>

      <div
        v-if="currentSection === 'nextspot'"
        class="nextspot"
      >
        <h2>
          FOTOKEM nextSPOT
          <span class="regmark">&reg;</span> - USE AGREEMENT
        </h2>
        <p>
          The following Use Agreement (the &ldquo;Agreement&rdquo;) applies to Foto-Kem Industries, Inc. (&ldquo;FotoKem&rdquo;) nextSPOT
          <span class="regmark">&reg;</span> Service (the &ldquo;Service&rdquo;). It is understood that Service Users (&ldquo;Customer&rdquo;)
          and Customer's &ldquo;Authorized Third Party&rdquo; are required to review and agree to the Terms of this Agreement,
          as posted on the FotoKem website or as provided through a nextSPOT
          <span class="regmark">&reg;</span> user interface. By signing on to the nextSPOT
          <span class="regmark">&reg;</span> Service, Customer and Customer's Authorized Third Party acknowledges and agrees to all Service Terms
          and it is understood that the Use Agreement shall be considered part of a Service Contract along with all other
          bid and acceptance documents relating to the establishment of Service.
        </p>
        <h3>1. DEFINITIONS</h3>
        <p>
          (a)
          <strong>"Account"</strong> means the administrative mechanism set up by FotoKem to manage Customer use of Service and enable
          FotoKem to bill Customer for any relevant use of Service.
          <br>(b)
          <strong>"Agreement"</strong> means all bids, purchase orders, and all other documents between FotoKem and Customer relating
          to services or materials contracted or provided, and includes this Use Agreement.
          <br>(c)
          <strong>"Contract"</strong> means the sum of all documents and written Agreements pertaining to the provision of Services
          or materials by FotoKem to Customer.
          <br>(d)
          <strong>"Party or Parties"</strong> means FotoKem and/or Customer.
          <br>(e)
          <strong>"Service" or "Services"</strong> means any and all FotoKem nextSPOT
          <span class="regmark">&reg;</span> Services and/or related Services provided by FotoKem in fulfillment of Customer Contract or Order.
          <br>(f)
          <strong>"Service Terms or Terms"</strong> means the Terms of this Use Agreement and any other terms stipulated in associated
          Contract documents.
          <br>(g)
          <strong>"Customer"</strong> means the Party Contracting Services from FotoKem and any employees, representatives, or agents
          of Customer.
          <br>(h)
          <strong>"Order"</strong> means any FotoKem accepted request for the provision of Service in the form of Customer Purchase
          Order and/or other authorized form of request.
          <br>(i)
          <strong>"Authorized Third Party"</strong> means any person or entity other than Customer or FotoKem, whom has been approved
          by Customer or FotoKem to access Service.
          <br>(J)
          <strong>"Third Party"</strong> means any person or entity other than Customer or FotoKem.
        </p>
        <h3>2. ACCESS TO SERVICE</h3>
        <p>
          Upon the establishment of a Customer Account for Service, FotoKem agrees to provide Customer access to Service. Depending
          on Customer use requirements, FotoKem may provide proprietary software to enable Service. Service shall be maintained
          for the term of Contract provided Customer adheres to all Contract Terms related to the provision of Service.
        </p>
        <h3>3. SERVICE INTERRUPTIONS. </h3>
        <p>
          (a) As FotoKem has contracted with Third Party vendors to provide Service, FotoKem shall not be held responsible for Service
          interruptions or loss of connectivity due to vendor issues.
          <br>(b) FotoKem may (but is not required to) pass-on to Customer any credit, rebate or other consideration FotoKem
          may receive from a vendor due to Service interruption or loss of connectivity.
        </p>
        <h3>4. CUSTOMER EQUIPMENT. </h3>
        <p>
          Customer shall be responsible to obtain and have in place all equipment needed to access Service. FotoKem shall have no obligation
          for securing or maintaining Customer equipment, and no liability for Customer's inability to access Service due
          to lack of or failure of Customer equipment, or where the use of additional or other Customer equipment would enable
          such access.
        </p>
        <h3>5. CHANGES. </h3>
        <p>
          FotoKem reserves the right at any time to make changes or additions to, or to replace any part of, the FotoKem network, Customer
          equipment specification, access control or other aspect of the Service, whether seeking to improve service or to
          lower costs or to conform to statutory requirements or requirements of regulatory bodies or Internet governing
          body or as FotoKem otherwise deems desirable. FotoKem may, but is not required to, make an effort to contact Customer
          to agree to timing for implementing changes, but does not assure success in doing so.
        </p>
        <h3>6. PERMITS, CONSENTS. </h3>
        <p>
          Customer shall, at Customer's expense, obtain any required permits, permissions, licenses, consents, or registrations deemed
          necessary by FotoKem or other authority, for installation and maintenance of the necessary equipment to access
          Service.
        </p>
        <h3>7. PROVISIONS REGARDING CUSTOMER USE OF SERVICE.</h3>
        <p>
          (a) Customer shall use the Services according to these Terms, and all other operating guides and instructions FotoKem notifies
          Customer of.
          <br>(b) Customer shall be considered solely responsible for the content of the files stored, distributed, transferred,
          collaborated on or transmitted through the Service by both Customer and Customer's Authorized Third Party.
          <br>(c) Customer shall not use the Service, or permit the Service to be used for any unlawful, fraudulent or unauthorized
          act, purpose or activity, or distribute, transfer, collaborate on or transmit any material that is unlawful, defamatory,
          offensive, threatening, abusive, obscene, or menacing, and constitutes or encourages a criminal offence, or could
          potentially give rise to civil liability; or take any action which is contrary to the acceptable use policies of
          any connected networks or Internet standards, or in any way that might place FotoKem in violation of any law or
          regulation.
          <br>(d) Customer and Customer's Authorized Third Party shall not violate or infringe any trademark patent, copyright,
          other intellectual property rights or similar rights, proprietary rights of publicity or privacy of any Third Party
          under the laws of any jurisdiction.
          <br>(e) Customer or Customer's Authorized Third Party shall not knowingly or recklessly transmit or distribute any
          material containing harmful or deleterious software viruses or any other hostile programming routines or codes
          designed to interrupt, corrupt, harm, destroy or limit the functionality of computer software, hardware or telecommunications
          equipment, or networks.
          <br>(f) Customer shall not allow or enable any unauthorized Third Party to access the Service, or use the Service in
          any way that in FotoKem's opinion may bring the Service or FotoKem into disrepute, or for any purpose other than
          those specified in the Service Contract.
          <br>(g) Following notice from FotoKem, an intellectual property owner, government authority or law enforcement agency,
          Customer and Customer's employees shall cooperate fully with any FotoKem investigation in response to such notice.
          Should Customer be found in violation of governing laws, Customer agrees to accept responsibility for any and all
          damages, fines, charges, or expenses associated with such notice and/or investigation.
          <br>(h) Customer acknowledges responsibility for any damages, charges, or expenses resulting from the introduction
          by Customer use of Services of any material containing harmful or deleterious software viruses or any other programming
          routines or codes designed to interrupt, destroy or limit the functionality of any computer software or hardware
          or telecommunications equipment or any other hostile computer program.
          <br>(i) Customer shall not make any representation or warranty to a Third Party or Authorized Third Party as to quality,
          availability, or fitness for a particular purpose, of the Service.
          <br>(j) In no event will FotoKem be liable for any loss or damage to any data stored on any FotoKem servers used as
          part of the Service. Customer is responsible for maintaining insurance coverage in respect to any loss or damage
          to data stored on any of Customer and/or FotoKem servers.
        </p>
        <h3>8. PROPERTY RIGHTS.</h3>
        <p>
          (a) Customer shall not violate and take all reasonable steps to ensure its employees, agents and Authorized Third Party do
          not violate intellectual property rights of any Third Party, including, but not limited to, any trademark, patent,
          copyright or trade secret.
          <br>(b) Customer shall sufficiently monitor Service use by Authorized Third Party, employees, and agents, to comply
          with the foregoing obligation.
          <br>(c) Customer or Customer's Authorized Third Party shall not use the Service or FotoKem's servers in violation of
          Third Party Intellectual Property Rights.
          <br>(d) Customer shall not seek to or actually circumvent FotoKem's security procedures and shall abide by all requirements
          imposed by FotoKem.
          <br>(e) All legal and beneficial rights in all FotoKem software provided to Customer for use of the Services shall
          remain at all times the property of FotoKem.
          <br>(f) To the extent of its ability to do so, FotoKem shall grant Customer a non-exclusive non-transferable license
          to use applicable FotoKem proprietary software during the term of Contract, for the purpose of using the Service,
          and for no other purpose.
          <br>(g) Customer shall not reproduce the software for any purpose, including back-up, without expressed written permission
          from FotoKem.
          <br>(h) Customer shall not modify, adapt, translate, reverse engineer, decompile or disassemble the software or create
          any derivative work based thereon or merge or include the software with or in any other software.
          <br>(i) FotoKem shall have the exclusive right to commence, defend, conduct and settle all proceedings involving infringement
          or alleged infringement of FotoKem's Intellectual Property Rights or relating to the validity thereof.
          <br>(j) Customer shall notify FotoKem immediately in writing of any suspected infringement of FotoKem's Intellectual
          Property by Customer's employees, agents, Authorized Third Party, or Third Party. Customer shall give all reasonable
          assistance to FotoKem in the investigation of any such infringement and provide documentation in compliance with
          FotoKem requests.
          <br>(k) Nothing in the Agreement shall act as an assignment by FotoKem to Customer of any copyright, patent, trademark,
          or other Intellectual Property Right belonging to FotoKem.
          <br>(l) Customer shall not use, whether in the course of business or otherwise, in relation to any goods, services,
          advertising or promotion, any registered or unregistered name, mark, design, logo or similar Intellectual Property
          Right of FotoKem, its vendors, or others. By way of illustration, no use may be made by Customer of the name FotoKem
          or any mark, logo or other Intellectual Property Rights without the expressed written permission of FotoKem. All
          Intellectual Property Rights and/or know-how generated or arising in the performance of or as a result of rendering
          the Service shall (to the extent not already vested in FotoKem) vest in and be the property solely of FotoKem.
        </p>
        <h3>9. SUSPENSION OF SERVICE.</h3>
        <p>
          (a) FotoKem may (in addition to all other rights) suspend Service, with or without prior notice, in whole or in part, if
          (i) required or convenient to comply with an order, instruction or request of, or to cooperate with, a government,
          court, or other authority; (ii) convenient for FotoKem or its vendor to perform work relating to upgrading, repair
          or maintenance of the Services or FotoKem Network or any part thereof including servers or any component or part
          of connectivity; (iii) Customer fails to timely pay any amount due to FotoKem; or (iv) Customer breaches any provision
          of these terms.
          <br>(b)FotoKem may, but is not required, prior to suspension under Section 9(a), or a scheduled service interruption,
          attempt to notify Customer of the timing and/or reason for suspension or interruption of Services.
        </p>
        <h3>10. REMEDY FOR LOSS OF CONNECTIVITY.</h3>
        <p>
          (a) As it is the responsibility of vendors (not FotoKem) to maintain connectivity to Service, FotoKem shall not be responsible
          or liable for interruption, failure or inability of Customer to access Services due to vendor service failure or
          interruption.
          <br>(b)No remedy shall be available to Customer, and Customer waives and releases any claim, for inability to obtain
          Service during a planned outage, interruption or suspension under Section 9, or termination under Section 11.
        </p>
        <h3>11. TERMINATION.</h3>
        <p>
          (a) FotoKem shall have the right to terminate Service, with or without notice, if (i) Customer fails to pay FotoKem as and
          when due; (ii) Customer breaches these Terms; (iii) a trustee, liquidator, receiver, or administrator is appointed
          over Customer or any part of Customer's assets, Customer becomes insolvent or unable or fails to pay its debts
          as they mature, makes an assignment or arrangement for benefit of creditors, bankruptcy insolvency or other debt
          relief proceedings are instituted by or against Customer, an application is made to a Court for relief from debt
          obligations or to restructure, Customer adopts a resolution or takes other action to effect its winding up or termination
          of its existence, or a Court of competent jurisdiction makes an order or takes any action to that effect; (iv)
          Customer defaults in the performance of or compliance with any agreement with FotoKem and (in case of a remediable
          breach) fails to remedy the breach within three (3) days of notice of breach being given in any form by FotoKem;
          (v) the Services can no longer be provided by FotoKem for any reason whatsoever; or (vi) Customer violates intellectual
          property rights of others, including but not limited to, infringing or misusing a Third Party's trademark, copyright,
          patent or trade secret.
          <br>(b) Customer may terminate the Service term by providing FotoKem 30 days' written notice if FotoKem fails in a
          material way to provide the Service. Should there be no enforceable service term, as stipulated by Contract, Customer
          may terminate Service by providing FotoKem 30 days written notice.
        </p>
        <h3>12. CONSEQUENCES OF TERMINATION.</h3>
        <p>
          (a) On termination for any reason Customer shall (i) immediately stop using the Service; (ii) stop use of any proprietary
          software belonging to FotoKem; (iii) return to FotoKem all software, hardware and other equipment which FotoKem
          may have provided Customer for the purpose of using Service.
        </p>
        <h3>13. LIMITATION OF LIABILITY. </h3>
        <p>
          FOTOKEM SHALL HAVE NO LIABILITY TO CUSTOMER WHATSOEVER FOR INTERRUPTION OR INABILITY OF CUSTOMER TO ACCESS SERVICE, OR FOR
          LOSS OF CONNECTIVITY, INCLUDING BUT NOT LIMITED TO LOSS OF DATA OR PRODUCTIVITY, OTHER THAN THE OBLIGATION TO USE
          REASONABLE EFFORTS IN SEEKING TO REESTABLISH SERVICE. FOTOKEM SHALL HAVE NO LIABILITY OR RESPONSIBILITY FOR EQUIPMENT
          FAILURE, WHETHER SUPPLIED BY FOTOKEM OR CUSTOMER. FOTOKEM MAKES NO WARRANTY, EXPRESS OR IMPLIED AND DISCLAIMS ALL
          IMPLIED WARRANTIES AS TO QUALITY, LEVEL, AMOUNT, FREEDOM FROM OR OTHER ASPECT OF: INTERRUPTION, CONNECTIVITY, DELIVERY,
          CONTINUITY, ERROR, DELAY, QUALITY, FITNESS FOR PURPOSE, SUITABILITY OR PERFORMANCE TO BE PROVIDED, ACCURACY OR
          CONTENT OF INFORMATION PASSING THROUGH ANY NETWORK, UNLESS EXPRESSLY STATED HEREIN. FOTOKEM SHALL NOT BE LIABLE
          TO CUSTOMER IN CONTRACT, TORT OR OTHERWISE, INCLUDING LIABILITY FOR NEGLIGENCE, OR FOR LOSS OF REVENUE, BUSINESS,
          CONTRACTS, ANTICIPATED SAVINGS, OR PROFITS, OR ANY INDIRECT, ECONOMIC OR CONSEQUENTIAL LOSS HOWSOEVER ARISING.
        </p>
        <h3>14. DISCLAIMERS &amp; WARRANTIES</h3>
        <p>
          (a) THE SERVICES ARE PROVIDED ON AN AS-IS, BASIS. FOTOKEM DOES NOT MAKE, AND EXPRESSLY DISCLAIMS, ANY AND ALL WARRANTIES,
          EXPRESS OR IMPLIED, IN REGARD TO ANY INFORMATION, PRODUCT, OR SERVICE FURNISHED BY IT UNDER THIS AGREEMENT, INCLUDING
          WITHOUT LIMITATION ANY AND ALL IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. IN NO
          EVENT SHALL FOTOKEM BE LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, OR PUNITIVE DAMAGES, EVEN IF
          ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE MAXIMUM LIABILITY OF FOTOKEM TO ANY USER FOR ANY LOSS, CLAIM, DAMAGE
          OR LIABILITY OF ANY KIND INCLUDING THAT WHICH MAY BE DUE TO FOTOKEM'S ACTUAL OR ALLEGED NEGLIGENCE, SHALL BE LIMITED
          TO THE AMOUNT PAID BY USER TO FOTOKEM FOR THE PROVISION OF THE SERVICE AT THE TIME THAT A CAUSE FOR CLAIM AROSE.
          (b) THE SERVICE INCORPORATES AS STANDARD THE PUBLISHED AES 256-BIT ENCRYPTION AND/OR RSA 2048-BIT ENCRYPTION. FOTOKEM
          ACCEPTS NO RESPONSIBILITY FOR THE BREAKING OF THESE ENCRYPTION STANDARDS AND ANY LOSSES THEREAFTER INCURRED TO
          CUSTOMER AS A RESULT OF ANY RELEVANT CODE BREAKING.
        </p>
        <h3>15. INDEMNITY AND UNDERTAKING.</h3>
        <p>
          Customer shall indemnify, defend and hold harmless FotoKem, its directors, officers, employees and agents from and against
          any and all liabilities, claims, costs and demands whatsoever (also including attorney's fees, costs, damages,
          fines, judgments and expenses), arising from claims by any Third Party (regardless of cause of action and whether
          suffered directly or indirectly by FotoKem or others) in connection with or relating to the use of the Service
          by Customer or breach of any Terms hereof by Customer.
        </p>
        <h3>16. LIQUIDATED DAMAGES. </h3>
        <p>
          Customer acknowledges that for each violation of a Third Party's intellectual property rights, FotoKem will have damages
          consisting of, but not limited to, costs to relay information concerning the claim and take measures to address
          the claim, and loss of reputation and business. Customer shall, in addition to performing the indemnity obligations
          above, pay FotoKem (at FotoKem's discretion), as liquidated damages, the sum of $5,000, on account of such damages.
          Customer acknowledges this liquidated damages provision represents reasonable compensation to FotoKem for the damages
          due to such breach. Nothing in this section 15 shall limit FotoKem's right to obtain injunctive and/or other provisional
          relief against Customer for breach of these terms.
        </p>
        <h3>17. FORCE MAJEURE. </h3>
        <p>
          (a) Customer acknowledges that FotoKem has no responsibility to indemnify Customer against or make correction for any loss
          or damage due to an act of God, inclement weather, failure or shortage of power, flood, earthquake, lightning or
          fire, strike, lock-out, trade dispute or labor disturbance, act or omission of government or other competent authority,
          war, military operation, acts of terrorism, riot, accident, or technical problems which make it impracticable to
          perform these Terms. (b) If the delay caused by an event referred to in section 16(a) lasts more than 30 days,
          either Party shall have the right, but not the obligation, to terminate the Service by giving written notice to
          the other Party.
        </p>
        <h3>18. GENERAL</h3>
        <p>
          (a)
          <strong>Notices:</strong> Notices hereunder shall be in writing and be given by mail or email to the other Party's address
          or other address as may be designated in writing from time to time by the other Party. Notice sent by mail shall
          be deemed to be delivered at the earlier of actual receipt or three days after dispatch. Notice sent by email shall
          be conspicuously labeled to call special attention to it and deemed to be delivered the business day after successful
          transmission.
          <br>(b)
          <strong> Assignment:</strong> Customer shall not assign or delegate or purport to assign or delegate any rights or obligations
          hereunder, without FotoKem's prior written consent.
          <br>(c)
          <strong>Modifications:</strong> FotoKem shall have the right by written notice to Customer to modify these Terms at any
          time to comply with regulations or other requirement imposed on FotoKem by vendors, government regulations, or
          other authority.
          <br>(d)
          <strong>Delay:</strong> Delay or failure by a Party to exercise a right or remedy shall be deemed not to waive the exercise
          of that right or remedy on another occasion, nor shall a single or partial exercise of rights or remedies, preclude
          other or future exercise of them. Remedies hereunder are cumulative and not exclusive of remedies provided by law.
          Any waiver shall be limited to the specific breach waived.
          <br>(e)
          <strong>Severability:</strong> If any provision of these terms shall be found by a court or other authority having jurisdiction
          to be invalid or unenforceable, that determination shall not affect the other provisions of these terms. All provisions
          not affected by the invalidity or unenforceability shall remain in full force and effect.
          <br>(f)
          <strong>Non-Exclusivity:</strong> These terms shall not prejudice any other arrangements or agreements FotoKem may have
          with any person or entity for Service. Nothing in these Terms shall restrict FotoKem from providing identical or
          similar Service to any other person or entity, on any Terms.
          <br>(g)
          <strong>Headings:</strong> Headings are for convenience only and shall not be used in the construction or interpretation
          of these Terms.
          <br>(h)
          <strong>Construction:</strong> In these Terms, where the context makes appropriate, the singular includes the plural and
          vice versa. These Terms are not intended to benefit or confer any right or benefit on any Third Party.
          <br>(i)
          <strong>Relationship:</strong> The Parties are independent and unrelated persons. Nothing herein shall be construed to
          create a partnership, joint venture, agency or similar arrangement or relationship. Neither Party shall have power
          or authority to make any commitment for or bind the other Party to any obligation. Neither Party shall make any
          representation or warranty, express or implied, on behalf of the other Party.
          <br>(j)
          <strong>Confidentiality:</strong> Customer acknowledges it may receive Confidential Information belonging to FotoKem which
          may include, but not be limited to, technical, contractual, business and financial information. Customer shall
          hold such information in strict confidence and take all reasonable steps to maintain its confidentiality. These
          restrictions shall not apply to the extent information or material is obtained by Customer from Third Parties without
          violation of confidentiality or non-disclosure obligation; publicly made available other than through Customer's
          fault; or disclosed to the public generally with prior written approval of FotoKem. Customer shall return any and
          all confidential information it may have in its possession to FotoKem within one week of FotoKem's written request.
          <br>(k)
          <strong>Survival:</strong> Sections 8, 10, 13, 14, 15, 16, 17, 18 and all other provisions which, by nature survive expiration
          or termination, shall continue in effect regardless of termination or expiration of these Terms, until performed
          or until by nature, such provisions have expired.
          <br>(l)
          <strong>Governing Law:</strong> These Terms shall be interpreted according to the internal laws of the State of California,
          and all applicable federal laws, rules and regulations, without regard for states rules on conflicts of laws. In
          a dispute between the Parties, exclusive jurisdiction and venue shall be in the United States District Court for
          the Central District of California or the Superior Court of the State of California for the County of Los Angeles.
          Customer consents to this jurisdiction and venue.
          <br>(n)
          <strong>Schedules:</strong> The schedules attached to these terms are incorporated herein and shall be deemed an integral
          part thereof
        </p>
        <h3>19. ENTIRE AGREEMENT</h3>
        <p>
          These Terms together with any Contract and any document expressly referred to in them, contain the entire Agreement between
          the Parties, save in the case of fraudulent misrepresentation or fraudulent concealment, supersede any previous
          agreements, arrangements, undertakings or proposals, written or oral, between Customer and FotoKem in relation
          to such matters. FotoKem reserves the right to alter these Terms at any time. FotoKem shall make best efforts to
          inform Customer that changes have been made to these terms and subject to FotoKem providing access to an up-to-date
          copy of the current terms via the FotoKem website or other written means. No oral explanation or oral information
          given by any Party shall alter the interpretation of these Terms.
        </p>
      </div>

      <div
        v-if="currentSection === 'globaldata'"
        class="globaldata"
      >
        <h2>
          FOTOKEM globalDATA
          <span class="regmark">&reg;</span> - USE AGREEMENT
        </h2>
        <p>
          The following Use Agreement (the "Agreement") applies to Foto-Kem Industries, Inc. ("FotoKem") globalDATA
          <span class="regmark">&reg;</span> Service (the "Service"). It is understood that Service Users ("Customer") are required to review and
          agree to the Terms of the this Agreement, as posted on the FotoKem website or as provided through the globalDATA
          <span class="regmark">&reg;</span> Customer sign-in user interface. By signing on to the globalDATA
          <span class="regmark">&reg;</span> network, Customer acknowledges and agrees to all Service Terms and it is understood that the Use Agreement
          shall be considered part of a Service Contract along with all other bid and acceptance documents relating to the
          establishment of Service.
        </p>

        <h3>1. DEFINITIONS</h3>
        <p>
          (a)
          <strong>"Account"</strong> means the administrative mechanism set up by FotoKem to manage Customer use of Service and enable
          FotoKem to bill Customer for any relevant use of Service.
          <br>(b)
          <strong>"Agreement"</strong> means all bids, purchase orders, and all other documents between FotoKem and Customer relating
          to services or materials contracted or provided, and includes this Use Agreement .
          <br>(c)
          <strong>"Contract"</strong> means the sum of all documents and written Agreements pertaining to the provision of Services
          or materials by FotoKem to Customer.
          <br>(d)
          <strong>"Party or Parties"</strong> means FotoKem and/or Customer.
          <br>(e)
          <strong>"Service"</strong> or
          <strong>"Services"</strong> means any and all FotoKem globalDATA
          <span class="regmark">&reg;</span> Services and/or related Services provided by FotoKem in fulfillment of Customer Contract or Order.
          <br>(f)
          <strong>"Service Terms or Terms"</strong> means the Terms of this Use Agreement and any other terms stipulated in associated
          Contract documents.
          <br>(g)
          <strong>"Customer"</strong> means the Party, Contracting Services from FotoKem and any employees, representatives, or agents
          of Customer.
          <br>(h)
          <strong>"Order"</strong> means any FotoKem accepted request for the provision of Service in the form of Customer Purchase
          Order and/or other authorized form of request.
          <br>(i)
          <strong>"Third Party"</strong> means any person or entity other than Customer or FotoKem.
        </p>

        <h3>2. ACCESS TO SERVICES.</h3>
        <p>
          Upon the establishment of a Customer Account for Service, FotoKem agrees to provide Customer FotoKem's proprietary
          software to enable access to Service. Service shall be maintained for the term of Contract provided Customer adheres
          to all Contract Terms related to the provision of Service.
        </p>
        <h3>3. SERVICE INTERRUPTIONS.</h3>
        <p>
          (a) As FotoKem has contracted with Third Party vendors to provide network connectivity for Service, FotoKem shall
          not be held responsible for Service interruptions or loss of connectivity due to vendor issues.
          <br>(b) FotoKem may (but is not required to) pass-on to Customer any credit, rebate or other consideration FotoKem
          may receive from a vendor due to Service interruption or loss of connectivity.
        </p>
        <h3>4. CUSTOMER EQUIPMENT.</h3>
        <p>
          Customer shall be responsible to obtain and have in place all equipment needed to access Service. FotoKem shall have
          no obligation for securing or maintaining Customer equipment, and no liability for Customer's inability to access
          Service due to lack of or failure of Customer equipment, or where the use of additional or other Customer equipment
          would enable such access.
        </p>
        <h3>5. CHANGES.</h3>
        <p>
          FotoKem reserves the right at any time to make changes or additions to, or to replace any part of, the FotoKem network,
          Customer equipment specification, access control or other aspect of the Service, whether seeking to improve service
          or to lower costs or to conform to statutory requirements or requirements of regulatory bodies or Internet governing
          body or as FotoKem otherwise deems desirable. FotoKem may, but is not required to, make an effort to contact Customer
          to agree to timing for implementing changes, but does not assure success in doing so.
        </p>
        <h3>6. PERMITS, CONSENTS.</h3>
        <p>
          Customer shall, at Customer's expense, obtain any required permits, permissions, licenses, consents, or registrations
          deemed necessary by FotoKem or other authority, for installation and maintenance of the necessary equipment to
          access Service.
        </p>
        <h3>7. PROVISIONS REGARDING CUSTOMER USE OF SERVICE.</h3>
        <p>
          (a) Customer shall use the Services according to these Terms, and all other operating guides and instructions FotoKem
          notifies Customer of.
          <br>(b) Customer is solely responsible for the content of the files stored, distributed, transferred, collaborated
          on or transmitted through the Service.
          <br>(c) Customer shall not use the Service or permit the Service to be used: (i) for any unlawful, fraudulent or unauthorized
          act, purpose or activity; (ii) to transmit unlawful, defamatory, offensive, threatening, abusive, obscene or menacing
          material; (iii) in any way that violates or infringes Intellectual Property, confidentiality, or other right of
          any person, firm or entity; (iv) in any way that allows or enables unauthorized access to the Service or the FotoKem
          Network; (v) in any way that may cause interference, corruption, harm or loss of control to any computer, network
          or system utilizing the Service, (vi) in violation of any law or regulation, or in a way that places FotoKem in
          violation of any law or regulation; (vii) in violation of these terms of use, or general rule policy or law covering
          a network or facility through which data may pass in Customer's use of the Service.
          <br>(d) Customer shall not: (i) allow any Third Party use or access to the Service; (ii) use the Service for purposes
          other than those specified in Service Contract; (iii) use the Service in any way that in FotoKem's opinion may
          bring the Service or FotoKem into disrepute; and/or (iv) make any representation or warranty to a Third Party as
          to quality, availability, or fitness for a particular purpose, of the Service.
          <br>(e) Following notice from FotoKem, an intellectual property owner, government authority or law enforcement agency,
          Customer and Customer's employees shall cooperate fully with any FotoKem investigation in response to such notice.
          Should Customer be found in violation of governing laws, Customer agrees to accept responsibility for any and all
          damages, fines, charges, or expenses associated with such notice and/or investigation.
          <br>(f) In no event will FotoKem be liable for any loss or damage to any data stored on any FotoKem servers used as
          part of the Service. Customer is responsible for maintaining insurance coverage in respect to any loss or damage
          to data stored on any of Customer and/or FotoKem servers.
          <br>(g) Customer acknowledges responsibility for any damages, charges, or expenses resulting from the introduction
          by Customer use of Services of any material containing harmful or deleterious software viruses or any other programming
          routines or codes designed to interrupt, destroy or limit the functionality of any computer software or hardware
          or telecommunications equipment or any other hostile computer program.
        </p>
        <h3>8. PROPERTY RIGHTS.</h3>
        <p>
          (a) Customer shall not violate and shall cause its employees to not violate intellectual property rights of any Third
          Party, including, but not limited to, any trademark, patent, copyright or trade secret.
          <br>(b) Customer shall monitor employees' use of the Services sufficiently to comply with the foregoing obligation.
          <br>(c) Customer shall not use the Service or FotoKem's servers in violation of Third Party Intellectual Property Rights.
          <br>(d) Customer shall not seek to or actually circumvent FotoKem's security procedures and shall abide by all requirements
          imposed by FotoKem.
          <br>(e) All legal and beneficial rights in all FotoKem software provided to Customer for use of the Services shall
          remain at all times the property of FotoKem.
          <br>(f) To the extent of its ability to do so, FotoKem shall grant Customer a non-exclusive non-transferable license
          to use FotoKem's proprietary software during the term of Contract, for the purpose of using the Service, and for
          no other purpose.
          <br>(g) Customer shall not reproduce the software for any purpose, including back-up, without expressed written permission
          from FotoKem.
          <br>(h) Customer shall not modify, adapt, translate, reverse engineer, decompile or disassemble the software or create
          any derivative work based thereon or merge or include the software with or in any other software.
          <br>(i) FotoKem shall have the exclusive right to commence, defend, conduct and settle all proceedings involving infringement
          or alleged infringement of FotoKem's Intellectual Property Rights or relating to the validity thereof.
          <br>(j) Customer shall notify FotoKem immediately in writing of any suspected infringement of FotoKem's Intellectual
          Property by Customer's employees, agents, or any other person or entity. Customer shall give all reasonable assistance
          to FotoKem in the investigation of any such infringement and provide documentation in compliance with FotoKem requests.
          <br>(k) Nothing in the Contract shall act as an assignment by FotoKem to Customer of any copyright, patent, trademark,
          or other Intellectual Property Right belonging to FotoKem.
          <br>(l) Customer shall not use, whether in the course of business or otherwise, in relation to any goods, services,
          advertising or promotion, any registered or unregistered name, mark, design, logo or similar Intellectual Property
          Right of FotoKem, its vendors, or others. By way of illustration, no use may be made by Customer of the name FotoKem
          or any mark, logo or other Intellectual Property Rights without the expressed written permission of FotoKem. All
          Intellectual Property Rights and/or know-how generated or arising in the performance of or as a result of rendering
          the Service shall (to the extent not already vested in FotoKem) vest in and be the property solely of FotoKem.
        </p>
        <h3>9. SUSPENSION OF SERVICE.</h3>
        <p>
          (a) FotoKem may (in addition to all other rights) suspend Service, with or without prior notice, in whole or in part,
          if (i) required or convenient to comply with an order, instruction or request of, or to cooperate with, a government,
          court, or other authority; (ii) convenient for FotoKem or its vendor to perform work relating to upgrading, repair
          or maintenance of the Services or FotoKem Network or any part thereof including servers or any component or part
          of connectivity; (iii) Customer fails to timely pay any amount due to FotoKem; or (iv) Customer breaches any provision
          of these terms. (b) FotoKem may, but is not required, prior to suspension under Section 9(a), or a scheduled service
          interruption, attempt to notify Customer of the timing and/or reason for suspension or interruption of Services.
        </p>
        <h3>10. REMEDY FOR LOSS OF CONNECTIVITY.</h3>
        <p>
          (a) As it is the responsibility of FotoKem's vendors (not FotoKem) to maintain fiber connectivity to Service, FotoKem
          shall not be responsible or liable for interruption, failure or inability of Customer to access Services due to
          vendor service failure or interruption. (b) No remedy shall be available to Customer, and Customer waives and releases
          any claim, for inability to obtain Service during a planned outage, interruption or suspension under Section 9,
          or termination under Section 11.
        </p>
        <h3>11. TERMINATION.</h3>
        <p>
          (a) FotoKem shall have the right to terminate Service, with or without notice, if (i) Customer fails to pay FotoKem
          as and when due; (ii) Customer breaches these Terms; (iii) a trustee, liquidator, receiver, or administrator is
          appointed over Customer or any part of Customer's assets, Customer becomes insolvent or unable or fails to pay
          its debts as they mature, makes an assignment or arrangement for benefit of creditors, bankruptcy insolvency or
          other debt relief proceedings are instituted by or against Customer, an application is made to a Court for relief
          from debt obligations or to restructure, Customer adopts a resolution or takes other action to effect its winding
          up or termination of its existence, or a Court of competent jurisdiction makes an order or takes any action to
          that effect; (iv) Customer defaults in the performance of or compliance with any agreement with FotoKem and (in
          case of a remediable breach) fails to remedy the breach within three (3) days of notice of breach being given in
          any form by FotoKem; (v) the Services can no longer be provided by FotoKem for any reason whatsoever; or (vi) Customer
          violates intellectual property rights of others, including but not limited to, infringing or misusing a Third Party's
          trademark, copyright, patent or trade secret.
          <br>(b) Customer may terminate the Service by providing FotoKem 30 days' written notice if FotoKem fails in a material
          way to provide the Service. Should there be no enforceable service term as stipulated by Contract, Customer may
          terminate Service by providing FotoKem 30 days written notice.
        </p>
        <h3>12. CONSEQUENCES OF TERMINATION.</h3>
        <p>
          (a) On termination for any reason Customer shall (i) immediately stop using the Service; (ii) stop use of any proprietary
          software belonging to FotoKem; (iii) return to FotoKem all software, hardware and other equipment which FotoKem
          may have provided Customer for purposes of using the Service.
        </p>
        <h3>13. LIMITATION OF LIABILITY.</h3>
        <p>
          FOTOKEM SHALL HAVE NO LIABILITY TO CUSTOMER WHATSOEVER FOR INTERRUPTION OR INABILITY OF CUSTOMER TO ACCESS SERVICE,
          OR FOR LOSS OF CONNECTIVITY, INCLUDING BUT NOT LIMITED TO LOSS OF DATA OR PRODUCTIVITY, OTHER THAN THE OBLIGATION
          TO USE REASONABLE EFFORTS IN SEEKING TO REESTABLISH THE CONNECTION. FOTOKEM SHALL HAVE NO LIABILITY OR RESPONSIBILITY
          FOR EQUIPMENT FAILURE, WHETHER SUPPLIED BY FOTOKEM OR CUSTOMER. FOTOKEM MAKES NO WARRANTY, EXPRESS OR IMPLIED AND
          DISCLAIMS ALL IMPLIED WARRANTIES AS TO QUALITY, LEVEL, AMOUNT, FREEDOM FROM OR OTHER ASPECT OF: INTERRUPTION, CONNECTIVITY,
          DELIVERY, CONTINUITY, ERROR, DELAY, QUALITY, FITNESS FOR PURPOSE, SUITABILITY OR PERFORMANCE TO BE PROVIDED, ACCURACY
          OR CONTENT OF INFORMATION PASSING THROUGH ANY NETWORK, UNLESS EXPRESSLY STATED HEREIN. FOTOKEM SHALL NOT BE LIABLE
          TO CUSTOMER IN CONTRACT, TORT OR OTHERWISE, INCLUDING LIABILITY FOR NEGLIGENCE, OR FOR LOSS OF REVENUE, BUSINESS,
          CONTRACTS, ANTICIPATED SAVINGS, OR PROFITS, OR ANY INDIRECT, ECONOMIC OR CONSEQUENTIAL LOSS HOWSOEVER ARISING.
        </p>
        <h3>14. DISCLAIMERS &amp; WARRANTIES</h3>
        <p>
          (a) THE SERVICES ARE PROVIDED ON AN AS-IS, BASIS. FOTOKEM DOES NOT MAKE, AND EXPRESSLY DISCLAIMS, ANY AND ALL WARRANTIES,
          EXPRESS OR IMPLIED, IN REGARD TO ANY INFORMATION, PRODUCT, OR SERVICE FURNISHED BY IT UNDER THIS AGREEMENT, INCLUDING
          WITHOUT LIMITATION ANY AND ALL IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. IN NO
          EVENT SHALL FOTOKEM BE LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, OR PUNITIVE DAMAGES, EVEN IF
          ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE MAXIMUM LIABILITY OF FOTOKEM TO ANY USER FOR ANY LOSS, CLAIM, DAMAGE
          OR LIABILITY OF ANY KIND INCLUDING THAT WHICH MAY BE DUE TO FOTOKEM'S ACTUAL OR ALLEGED NEGLIGENCE, SHALL BE LIMITED
          TO THE AMOUNT PAID BY USER TO FOTOKEM FOR THE PROVISION OF THE SERVICE AT THE TIME THAT A CAUSE FOR CLAIM AROSE.
          (b) THE SERVICE INCORPORATES AS STANDARD THE PUBLISHED AES 256-BIT ENCRYPTION AND/OR RSA 2048-BIT ENCRYPTION. FOTOKEM
          ACCEPTS NO RESPONSIBILITY FOR THE BREAKING OF THESE ENCRYPTION STANDARDS AND ANY LOSSES THEREAFTER INCURRED TO
          CUSTOMER AS A RESULT OF ANY RELEVANT CODE BREAKING.
        </p>
        <h3>15. INDEMNITY AND UNDERTAKING.</h3>
        <p>
          Customer shall indemnify, defend and hold harmless FotoKem, its directors, officers, employees and agents from and
          against any and all liabilities, claims, costs and demands whatsoever (also including attorney's fees, costs, damages,
          fines, judgments and expenses), arising from claims by any Third Party (regardless of cause of action and whether
          suffered directly or indirectly by FotoKem or others) in connection with or relating to the use of the Service
          by Customer or breach of any Terms hereof by Customer.
        </p>
        <h3>16. LIQUIDATED DAMAGES.</h3>
        <p>
          Customer acknowledges that for each violation of a Third Party's intellectual property rights, FotoKem will have
          damages consisting of but not limited to costs to relay information concerning the claim and take measures to address
          the claim, and loss of reputation and business. Customer shall, in addition to performing the indemnity obligations
          above, pay FotoKem (at FotoKem's discretion), as liquidated damages, the sum of $5,000, on account of such damages.
          Customer acknowledges this liquidated damages provision represents reasonable compensation to FotoKem for the damages
          due to such breach. Nothing in this section 15 shall limit FotoKem's right to obtain injunctive and/or other provisional
          relief against Customer for breach of these terms.
        </p>
        <h3>17. FORCE MAJEURE.</h3>
        <p>
          (a) Customer acknowledges that FotoKem has no responsibility to indemnify Customer against or make correction for
          any loss or damage due to an act of God, inclement weather, failure or shortage of power, flood, earthquake, lightning
          or fire, strike, lock-out, trade dispute or labor disturbance, act or omission of government or other competent
          authority, war, military operation, acts of terrorism, riot, accident, or technical problems which make it impracticable
          to perform these Terms. (b) If the delay caused by an event referred to in section 16(a) lasts more than 30 days,
          either Party shall have the right, but not the obligation, to terminate the Service by giving written notice to
          the other Party.
        </p>
        <h3>18. GENERAL</h3>
        <p>
          (a)
          <strong>Notices:</strong> Notices hereunder shall be in writing and be given by mail or email to the other Party's address
          or other address as may be designated in writing from time to time by the other Party. Notice sent by mail shall
          be deemed to be delivered at the earlier of actual receipt or three days after dispatch. Notice sent by email shall
          be conspicuously labeled to call special attention to it and deemed to be delivered the business day after successful
          transmission.
          <br>(b)
          <strong>Assignment:</strong> Customer shall not assign or delegate or purport to assign or delegate any rights or obligations
          hereunder, without FotoKem's prior written consent.
          <br>(c)
          <strong>Modifications:</strong> FotoKem shall have the right by written notice to Customer to modify these Terms at any
          time to comply with regulations or other requirement imposed on FotoKem by vendors, government regulations, or
          other authority.
          <br>(d)
          <strong>Delay:</strong> Delay or failure by a Party to exercise a right or remedy shall be deemed not to waive the exercise
          of that right or remedy on another occasion, nor shall a single or partial exercise of rights or remedies, preclude
          other or future exercise of them. Remedies hereunder are cumulative and not exclusive of remedies provided by law.
          Any waiver shall be limited to the specific breach waived.
          <br>(e)
          <strong>Severability:</strong> If any provision of these terms shall be found by a court or other authority having jurisdiction
          to be invalid or unenforceable, that determination shall not affect the other provisions of these terms. All provisions
          not affected by the invalidity or unenforceability shall remain in full force and effect.
          <br>(f)
          <strong>Non-Exclusivity:</strong> These terms shall not prejudice any other arrangements or agreements FotoKem may have
          with any person or entity for Service. Nothing in these Terms shall restrict FotoKem from providing identical or
          similar Service to any other person or entity, on any Terms.
          <br>(g)
          <strong>Headings:</strong> Headings are for convenience only and shall not be used in the construction or interpretation
          of these Terms.
          <br>(h)
          <strong>Construction:</strong> In these Terms, where the context makes appropriate, the singular includes the plural and
          vice versa. These Terms are not intended to benefit or confer any right or benefit on any Third Party.
          <br>(i)
          <strong>Relationship:</strong> The Parties are independent and unrelated persons. Nothing herein shall be construed to
          create a partnership, joint venture, agency or similar arrangement or relationship. Neither Party shall have power
          or authority to make any commitment for or bind the other Party to any obligation. Neither Party shall make any
          representation or warranty, express or implied, on behalf of the other Party.
          <br>(j)
          <strong>Confidentiality:</strong> Customer acknowledges it may receive Confidential Information belonging to FotoKem which
          may include, but not be limited to, technical, contractual, business and financial information. Customer shall
          hold such information in strict confidence and take all reasonable steps to maintain its confidentiality. These
          restrictions shall not apply to the extent information or material is obtained by Customer from Third Parties without
          violation of confidentiality or non-disclosure obligation; publicly made available other than through Customer's
          fault; or disclosed to the public generally with prior written approval of FotoKem. Customer shall return any and
          all confidential information it may have in its possession to FotoKem within one week of FotoKem's written request.
          <br>(k)
          <strong>Survival:</strong> Sections 8, 10, 13, 14, 15, 16, 17, 18 and all other provisions which, by nature survive expiration
          or termination, shall continue in effect regardless of termination or expiration of these Terms, until performed
          or until by nature, such provisions have expired.
          <br>(l)
          <strong>Governing Law:</strong> These Terms shall be interpreted according to the internal laws of the State of California,
          and all applicable federal laws, rules and regulations, without regard for states rules on conflicts of laws. In
          a dispute between the Parties, exclusive jurisdiction and venue shall be in the United States District Court for
          the Central District of California or the Superior Court of the State of California for the County of Los Angeles.
          Customer consents to this jurisdiction and venue.
          <br>(n)
          <strong>Schedules:</strong> The schedules attached to these terms are incorporated herein and shall be deemed an integral
          part thereof
        </p>
        <h3>19. ENTIRE AGREEMENT</h3>
        <p>
          These Terms together with any Contract and any document expressly referred to in them, contain the entire Agreement
          between the Parties, save in the case of fraudulent misrepresentation or fraudulent concealment, supersede any
          previous agreements, arrangements, undertakings or proposals, written or oral, between Customer and FotoKem in
          relation to such matters. FotoKem reserves the right to alter these Terms at any time. FotoKem shall make best
          efforts to inform Customer that changes have been made to these terms and subject to FotoKem providing access to
          an up-to-date copy of the current terms via the FotoKem website or other written means. No oral explanation or
          oral information given by any Party shall alter the interpretation of these Terms.
        </p>
      </div>
    </div>

    <fki-footer is-active>
      <fki-footer-nav is-active />
    </fki-footer>
  </fki-container>
</template>

<script>
import { FKISubNav, FKIFooterNav } from '@/components/FKINav'
import { FKIContainer, FKIContainerHeader } from '@/components/FKIContainer'
import { FKIFooter } from '@/components/FKIApp'

export default {
  name: 'Terms',
  components: {
    'fki-sub-nav': FKISubNav,
    'fki-container': FKIContainer,
    'fki-container-header': FKIContainerHeader,
    'fki-footer': FKIFooter,
    'fki-footer-nav': FKIFooterNav
  },
  props: {
    agreement: {
      type: String,
      default: 'general'
    }
  },
  emits: ['isLoading', 'setBackgrounds'],
  data () {
    return {
      currentSection: null,
      page: { color: 'blue', body: 'Terms and Conditions / User Agreements', slides: [] },
      items: [
        {
          name: 'Gerneral Terms and Conditions',
          color: 'blue',
          slug: 'terms/general'
        },
        {
          name: 'EQUIPMENT RENTAL AND SERVICE AGREEMENT',
          color: 'blue',
          slug: 'terms/rental'
        },
        {
          name: 'CONDITIONS FOR NETWORKED SERVICES',
          color: 'blue',
          slug: 'terms/network'
        },
        {
          name: 'NEXTSPOT® USER AGREEMENT',
          color: 'blue',
          slug: 'terms/nextspot'
        },
        {
          name: 'GLOBALDATA® USER AGREEMENT',
          color: 'blue',
          slug: 'terms/globaldata'
        }
      ]
    }
  },
  watch: {
    $route: 'checkParams'
  },
  async created () {
    if (this.$route.path === '/terms-and-conditions') {
      this.$router
        .push({
          path: '/terms-and-conditions/general'
        })
        .catch(() => {})
    } else if (this.$route.path === '/terms') {
      this.$router
        .push({
          path: '/terms/general'
        })
        .catch(() => {})
    }

    this.getCurrentSection()
  },
  methods: {
    async checkParams () {
      if (this.$route.path === '/terms-and-conditions') {
        this.$router
          .push({
            path: '/terms-and-conditions/general'
          })
          .catch(() => {})
      } else if (this.$route.path === '/terms') {
        this.$router
          .push({
            path: '/terms/general'
          })
          .catch(() => {})
      }
      this.getCurrentSection()
    },
    getCurrentSection () {
      const slug = this.$route.params.agreement
      this.currentSection = slug
    }
  }
}
</script>
<style lang="scss" scoped>
.fki-terms {
  position: relative;
  padding: 0 5.55vw 45px;
  display: flex;
  width:100%;
  float:left;
  text-align: left;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width:100%;
  h2 {
    font-size: 20px;
    padding: 12px 0;
    margin: 0;
    font-style: normal;
    font-weight: 700;
    font-variant: normal;
    line-height: 1.1;
    color: inherit;
  }
  h3 {
    font-size: 16px;
    padding: 12px 0;
    margin: 0;
    font-style: normal;
    font-weight: 700;
    font-variant: normal;
    line-height: 1.1;
    color: inherit;
  }
  p {
    font-size: 14px;
    margin: 0 0 10px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    padding: 2px 16px 0;
    line-height: 18px;
    letter-spacing: 1;
    //margin-bottom: 6px;
}
}
</style>
